import React, { useState, useEffect } from "react";
import {
  Table,
  Button,
  Spinner,
  Toast,
  ToastContainer,
  Form,
  Badge,
  Pagination,
} from "react-bootstrap";
import { faEdit, faEye, faComments } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import "../Components/JTProjectList.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import api from "../services/api"; // <-- Import your custom Axios instance

const JTProjectList = () => {
  const [unreadCounts, setUnreadCounts] = useState({});
  const [projectNames, setProjectNames] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [cascadingNames, setCascadingNames] = useState([]);
  const email = localStorage.getItem("username");

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(25);

  const [filters, setFilters] = useState({
    unit: "",
    year: "",
    month: "",
    projectName: "",
    jobNumber: "",
    status: "",
    dpExecutive: "",
    scriptingExecutive: "",
  });

  const navigate = useNavigate();

  // -------------------------------
  // 1. Fetch cascading names
  // -------------------------------
  useEffect(() => {
    const fetchCascadingNames = async () => {
      try {
        if (!email) throw new Error("Email not found");

        // The interceptor in `api.js` attaches the token automatically,
        // so we only need to call `api.get(...)` with the endpoint path.
        const response = await api.get(`users/cascading_names/?email=${email}`);

        // Axios automatically parses JSON, so data is in response.data
        setCascadingNames(response.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchCascadingNames();
  }, [email]);

  // -------------------------------
  // 2. Fetch project names
  // -------------------------------
  useEffect(() => {
    const fetchProjectNames = async () => {
      setLoading(true);
      setError("");

      try {
        if (cascadingNames.length === 0) {
          setLoading(false);
          return;
        }

        // If your backend expects multiple "name" parameters,
        // you can still pass them as query string.
        const cascadingNamesParam = cascadingNames.join("&name=");

        // Make a GET request via Axios. The baseURL is set to http://localhost:8000/
        const response = await api.get(
          `api/segments/fetch-segments-by-name?name=${cascadingNamesParam}`
        );

        // Extract the data
        const data = response.data;

        // Format date fields
        const formattedProjectNames = data.map((project) => ({
          ...project,
          actualDateOfFinalOutput: project.actual_date_of_final_output
            ? moment(project.actual_date_of_final_output).format("YYYY-MM-DD")
            : null,
          actualDateOfLink: project.actual_date_of_link
            ? moment(project.actual_date_of_link).format("YYYY-MM-DD")
            : null,
          expectedDateOfFinalOutput: project.expected_date_of_final_output
            ? moment(project.expected_date_of_final_output).format("YYYY-MM-DD")
            : null,
          expectedDateOfLink: project.expected_date_of_link
            ? moment(project.expected_date_of_link).format("YYYY-MM-DD")
            : null,
        }));

        setProjectNames(formattedProjectNames);
        setFilteredProjects(formattedProjectNames);
      } catch (err) {
        setError("An error occurred while fetching projects.");
      } finally {
        setLoading(false);
      }
    };

    fetchProjectNames();
  }, [cascadingNames]);

  // -------------------------------
  // Handle filter changes
  // -------------------------------
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prev) => ({ ...prev, [name]: value }));
  };

  // -------------------------------
  // Filter and sort projects
  // -------------------------------
  useEffect(() => {
    const filtered = projectNames.filter((project) => {
      return (
        (filters.unit === "" ||
          (project.unit && project.unit.toLowerCase().includes(filters.unit.toLowerCase()))) &&
        (filters.year === "" ||
          (project.year && project.year.toString().includes(filters.year))) &&
        (filters.month === "" ||
          (project.month && project.month.toLowerCase().includes(filters.month.toLowerCase()))) &&
        (filters.projectName === "" ||
          (project.project_name && project.project_name.toLowerCase().includes(filters.projectName.toLowerCase()))) &&
        (filters.jobNumber === "" ||
          (project.job_number && project.job_number.toLowerCase().includes(filters.jobNumber.toLowerCase()))) &&
        (filters.status === "" ||
          (project.status && project.status.toLowerCase().includes(filters.status.toLowerCase()))) &&
        (filters.dpExecutive === "" ||
          (project.contact_person_for_dp && project.contact_person_for_dp.toLowerCase().includes(filters.dpExecutive.toLowerCase()))) &&
        (filters.scriptingExecutive === "" ||
          (project.contact_person_for_scripting && project.contact_person_for_scripting.toLowerCase().includes(filters.scriptingExecutive.toLowerCase())))
      );
    });

    // Sort by status: open -> hold -> closed
    const statusOrder = { open: 1, hold: 2, closed: 3 };
    const sorted = filtered.sort((a, b) => {
      const statusA = statusOrder[a.status?.toLowerCase()] || 4;
      const statusB = statusOrder[b.status?.toLowerCase()] || 4;
      return statusA - statusB;
    });

    setFilteredProjects(sorted);
    setCurrentPage(1); // Reset to the first page when filters change
  }, [filters, projectNames]);

  // -------------------------------
  // Pagination logic
  // -------------------------------
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredProjects.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const renderPagination = () => {
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(filteredProjects.length / itemsPerPage); i++) {
      pageNumbers.push(i);
    }

    return (
      <Pagination>
        {pageNumbers.map((number) => (
          <Pagination.Item
            key={number}
            active={number === currentPage}
            onClick={() => paginate(number)}
          >
            {number}
          </Pagination.Item>
        ))}
      </Pagination>
    );
  };

  // -------------------------------
  // 3. Discussion-related calls
  // -------------------------------
  // a) fetch segment ID, then navigate to discussion page
  const handleDiscussion = async (project) => {
    try {
      const unreadCount = await fetchUnreadCount1(project);
      if (unreadCount > 0) {
        alert(`You have ${unreadCount} unread comments.`);
      }

      const { job_number, segment_name } = project;
      // Using Axios instead of fetch
      const response = await api.get(
        `api/segments/fetch_segment_id_by_job_and_name?job_number=${job_number}&segment_name=${segment_name}`
      );

      const { segmentId } = response.data;
      if (!segmentId) {
        alert("Segment ID not found!");
        return;
      }

      navigate("/discussion", { state: { segmentId } });
    } catch (error) {
      console.error("Error fetching segment ID:", error);
      alert("An error occurred while fetching segment ID.");
    }
  };

  // b) fetch unread count
  const fetchUnreadCount1 = async (project) => {
    try {
      const { job_number, segment_name } = project;
      // Using Axios
      const response = await api.get(
        `api/segments/fetch_unread_count?job_number=${job_number}&segment_name=${segment_name}`
      );
      const { unreadCount } = response.data;
      return unreadCount || 0;
    } catch (error) {
      console.error("Error fetching unread count:", error);
      return 0; // Default to 0 if an error occurs
    }
  };

  // -------------------------------
  // Render
  // -------------------------------
  return (
    <div className="container mt-4" style={{ width: "100%" }}>
      <div className="tableEffect" style={{ width: "100%", margin: "auto" }}>
        <div className="text-center mb-3">
          <Button variant="primary" onClick={() => navigate("/create-new")}>
            + Add New Project
          </Button>
        </div>

        <div className="filters-container mb-4">
          <Form>
            <div className="row g-2">
              <div className="col-md-2">
                <Form.Control
                  className="form-input"
                  type="text"
                  placeholder="Year"
                  name="year"
                  value={filters.year}
                  onChange={handleFilterChange}
                />
              </div>
              <div className="col-md-2">
                <Form.Control
                  className="form-input"
                  type="text"
                  placeholder="Month"
                  name="month"
                  value={filters.month}
                  onChange={handleFilterChange}
                />
              </div>
              <div className="col-md-2">
                <Form.Control
                  className="form-input"
                  type="text"
                  placeholder="Project Name"
                  name="projectName"
                  value={filters.projectName}
                  onChange={handleFilterChange}
                />
              </div>
              <div className="col-md-2">
                <Form.Control
                  className="form-input"
                  type="text"
                  placeholder="Job Number"
                  name="jobNumber"
                  value={filters.jobNumber}
                  onChange={handleFilterChange}
                />
              </div>
              <div className="col-md-2">
                <Form.Control
                  className="form-input"
                  type="text"
                  placeholder="Status"
                  name="status"
                  value={filters.status}
                  onChange={handleFilterChange}
                />
              </div>
              <div className="col-md-2">
                <Form.Control
                  className="form-input"
                  type="text"
                  placeholder="DP Executive"
                  name="dpExecutive"
                  value={filters.dpExecutive}
                  onChange={handleFilterChange}
                />
              </div>
              <div className="col-md-2">
                <Form.Control
                  className="form-input"
                  type="text"
                  placeholder="Scripting Executive"
                  name="scriptingExecutive"
                  value={filters.scriptingExecutive}
                  onChange={handleFilterChange}
                />
              </div>
            </div>
          </Form>
        </div>

        {loading ? (
          <div className="loading-container d-flex justify-content-center align-items-center">
            <Spinner animation="border" variant="primary" />
            <p className="ms-2">Loading projects...</p>
          </div>
        ) : error ? (
          <div className="error-container alert alert-danger">{error}</div>
        ) : filteredProjects.length === 0 ? (
          <div className="empty-container alert alert-info">No projects found.</div>
        ) : (
          <>
            <div className="responsive-table">
              <Table bordered hover className="project-list-table">
                <thead>
                  <tr>
                    <th>SNO</th>
                    <th>UNIT</th>
                    <th>YEAR</th>
                    <th>MONTH</th>
                    <th>PROJECT NAME</th>
                    <th>JOB NUMBER</th>
                    <th>SEG NO</th>
                    <th>SEGMENT NAME</th>
                    <th>CONTACT PERSON (Research)</th>
                    <th>CONTACT PERSON (Script)</th>
                    <th>EXPECTED FINAL LINK</th>
                    <th>ACTUAL FINAL LINK</th>
                    <th>CONTACT PERSON (DP)</th>
                    <th>EXPECTED DATE OF FINAL OUTPUT</th>
                    <th>ACTUAL DATE OF FINAL OUTPUT</th>
                    <th>STATUS</th>
                    <th>VIEW</th>
                    <th>EDIT</th>
                    <th>COMMENTS</th>
                  </tr>
                </thead>
                <tbody>
                  {currentItems.map((project, index) => {
                    // Color formatting
                    const finalOutputStyle =
                      project.actualDateOfFinalOutput &&
                      project.expectedDateOfFinalOutput
                        ? moment(project.actualDateOfFinalOutput).isAfter(
                            project.expectedDateOfFinalOutput
                          )
                          ? { color: "red" }
                          : { color: "green" }
                        : {};

                    const finalLinkStyle =
                      project.actualDateOfLink && project.expectedDateOfLink
                        ? moment(project.actualDateOfLink).isAfter(
                            project.expectedDateOfLink
                          )
                          ? { color: "red" }
                          : { color: "green" }
                        : {};

                    // Check if the expected dates are today
                    const isToday = (date) => moment(date).isSame(moment(), "day");
                    const highlightRow =
                      isToday(project.expectedDateOfFinalOutput) ||
                      isToday(project.expectedDateOfLink);

                    if (highlightRow) {
                      console.log(
                        `Highlighting row for project ${project.project_name} with expectedDateOfFinalOutput: ${project.expectedDateOfFinalOutput} and expectedDateOfLink: ${project.expectedDateOfLink}`
                      );
                    }

                    return (
                      <tr
                        key={index}
                        style={highlightRow ? { backgroundColor: "yellow" } : {}}
                      >
                        <td>{indexOfFirstItem + index + 1}</td>
                        <td>{project.unit || "-"}</td>
                        <td>{project.year || "-"}</td>
                        <td>{project.month || "-"}</td>
                        <td>{project.project_name || "-"}</td>
                        <td>{project.job_number || "-"}</td>
                        <td>{project.no_of_segments || "-"}</td>
                        <td>{project.segment_name || "-"}</td>
                        <td>{project.contact_person_in_research || "-"}</td>
                        <td>{project.contact_person_for_scripting || "-"}</td>
                        <td style={finalLinkStyle}>
                          {project.expectedDateOfLink || "-"}
                        </td>
                        <td style={finalLinkStyle}>
                          {project.actualDateOfLink || "-"}
                        </td>
                        <td>{project.contact_person_for_dp || "-"}</td>
                        <td style={finalOutputStyle}>
                          {project.expectedDateOfFinalOutput || "-"}
                        </td>
                        <td style={finalOutputStyle}>
                          {project.actualDateOfFinalOutput || "-"}
                        </td>
                        <td>{project.status || "-"}</td>
                        <td>
                          <FontAwesomeIcon
                            icon={faEye}
                            className="icon-button"
                            onClick={() =>
                              navigate("/project_detailed_view", {
                                state: { project },
                              })
                            }
                          />
                        </td>
                        <td>
                          <FontAwesomeIcon
                            icon={faEdit}
                            className="icon-button"
                            onClick={() =>
                              navigate("/edit", {
                                state: {
                                  jobNumber: project.job_number,
                                  editMode: true,
                                },
                              })
                            }
                          />
                        </td>
                        <td>
                          <FontAwesomeIcon
                            icon={faComments}
                            className="icon-button"
                            onClick={() => handleDiscussion(project)}
                          />
                          <Badge
                            pill
                            bg={project.unread_count === 0 ? "success" : "danger"}
                            className="ms-2"
                          >
                            {project.unread_count || 0}
                          </Badge>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
            <div className="d-flex justify-content-center mt-4">
              {renderPagination()}
            </div>
          </>
        )}
      </div>

      <ToastContainer position="bottom-end" className="p-3">
        <Toast
          onClose={() => setShowSuccessToast(false)}
          show={showSuccessToast}
          delay={3000}
          autohide
          bg="success"
        >
          <Toast.Header>
            <strong className="me-auto">Success</strong>
          </Toast.Header>
          <Toast.Body>{successMessage}</Toast.Body>
        </Toast>
      </ToastContainer>
    </div>
  );
};

export default JTProjectList;