import React, { useEffect, useState } from "react";
import {
  CircularProgress,
  Alert,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Avatar,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { ArrowBack, ArrowForward, ContentCopy } from "@mui/icons-material";
import api from "../services/api";

const TodaySummary = () => {
  const [summary, setSummary] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [reportees, setReportees] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(0);

  useEffect(() => {
    const fetchReportees = async () => {
      try {
        const response = await api.get("users/get_reportees/", {
          headers: {
            Authorization: `Token ${localStorage.getItem("token")}`,
          },
        });
        setReportees(response.data.reportees || []);
      } catch (err) {
        console.error("Failed to fetch reportees:", err);
      }
    };

    fetchReportees();
  }, []);

  useEffect(() => {
    if (reportees.length > 0) {
      fetchSummary(reportees[selectedIndex]);
    } else {
      setLoading(false);
    }
  }, [reportees, selectedIndex]);

  const fetchSummary = async (email) => {
    try {
      setLoading(true);
      setSummary(null);
      setError(null);
      const response = await api.get(`timesheet/api/today/?email=${email}`);
      setSummary(response.data.today_summary);
    } catch (err) {
      setError(err.message || "Failed to fetch summary");
    } finally {
      setLoading(false);
    }
  };

  const handleNext = () => {
    setSelectedIndex((prevIndex) => (prevIndex + 1) % reportees.length);
  };

  const handlePrev = () => {
    setSelectedIndex((prevIndex) =>
      prevIndex === 0 ? reportees.length - 1 : prevIndex - 1
    );
  };

  const handleSelectionChange = (event) => {
    setSelectedIndex(event.target.value);
  };

  const copyTableToClipboard = () => {
    if (!summary) return;

    const tableText = Object.entries(summary)
      .map(
        ([project, tasks]) =>
          `${project}\n${Object.entries(tasks)
            .map(([task, duration]) => `  - ${task}: ${duration} min`)
            .join("\n")}`
      )
      .join("\n\n");

    navigator.clipboard.writeText(tableText).then(() => {
      alert("Summary copied to clipboard!");
    });
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
        <Typography variant="body1" style={{ marginLeft: "10px" }}>
          Loading today's summary...
        </Typography>
      </Box>
    );
  }

  if (error) {
    return <Alert severity="error">{error}</Alert>;
  }

  return (
    <Paper elevation={3} style={{ padding: "24px", maxWidth: "800px", margin: "20px auto" }}>
      <Typography variant="h4" gutterBottom>
        Today's Summary
      </Typography>

      {/* REPORTEE SELECTION DROPDOWN */}
      <FormControl fullWidth variant="outlined" style={{ marginBottom: "20px" }}>
        <InputLabel>Reportee</InputLabel>
        <Select
          value={selectedIndex}
          onChange={handleSelectionChange}
          label="Reportee"
        >
          {reportees.map((reportee, index) => (
            <MenuItem key={index} value={index}>
              {reportee}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* COPY BUTTON */}
      <IconButton onClick={copyTableToClipboard} color="primary" aria-label="copy table" style={{ marginBottom: "10px" }}>
        <ContentCopy />
      </IconButton>

      {/* SUMMARY TABLE OR NO SUMMARY MESSAGE */}
      {!summary || Object.keys(summary).length === 0 ? (
        <Alert severity="info">No summary available for today.</Alert>
      ) : (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Project</TableCell>
                <TableCell>Task</TableCell>
                <TableCell align="right">Duration (minutes)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(summary).map((project, projIndex) =>
                Object.keys(summary[project]).map((task, taskIndex) => (
                  <TableRow key={`${projIndex}-${taskIndex}`}>
                    {taskIndex === 0 && (
                      <TableCell rowSpan={Object.keys(summary[project]).length}>{project}</TableCell>
                    )}
                    <TableCell>{task}</TableCell>
                    <TableCell align="right">{summary[project][task]}</TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Paper>
  );
};

export default TodaySummary;