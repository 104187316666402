import React, { useState } from "react";
import { Container, Nav } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { AiOutlineHome, AiOutlineTable } from "react-icons/ai";
import FilterModal from "./FilterModal";
import "./TSSide.css";

const TSSide = () => {
  const location = useLocation();
  const currentPath = location.pathname;
  const [showFilterModal, setShowFilterModal] = useState(false);

  return (
    <Container fluid className="ts-sidebar-container">
      <div className="ts-sidebar">
        <Nav className="ts-flex-column">
          {/* Home Navigation */}
        

          {/* Today's Summary */}
          <div className="ts-nav-link-container">
            <Nav.Link
              href="/timesheet-today"
              className={`ts-nav-link ${currentPath.startsWith("/timesheet-today") ? "ts-active" : ""}`}
            >
              <AiOutlineTable className="ts-icon" />
              <span>Today's Summary</span>
            </Nav.Link>
          </div>

          {/* Filter Summary */}
          <div className="ts-nav-link-container">
            <Nav.Link
              href="/timesheet-date"
              className={`ts-nav-link ${currentPath.startsWith("/timesheet-date") ? "ts-active" : ""}`}
            >
              <AiOutlineTable className="ts-icon" />
              <span>Filter Summary</span>
            </Nav.Link>
          </div>

          {/* Project Summary */}
          <div className="ts-nav-link-container">
            <Nav.Link
              href="/timesheet-project"
              className={`ts-nav-link ${currentPath.startsWith("/timesheet-project") ? "ts-active" : ""}`}
            >
              <AiOutlineTable className="ts-icon" />
              <span>Project Summary</span>
            </Nav.Link>
          </div>

          {/* Add Tasks */}
          <div className="ts-nav-link-container">
            <Nav.Link
              href="/timesheet"
              className={`ts-nav-link ${currentPath === "/timesheet" ? "ts-active" : ""}`}
            >
              <AiOutlineTable className="ts-icon" />
              <span>Add Tasks</span>
            </Nav.Link>
          </div>
        </Nav>
      </div>

      <FilterModal showModal={showFilterModal} setShowModal={setShowFilterModal} />
    </Container>
  );
};

export default TSSide;
