import React, { useState, useEffect } from 'react';
import {Button, Form, Row, Col, Alert} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { saveAs } from 'file-saver';
import { Workbook } from 'exceljs';
import './Awn3.css';
import { animateScroll as scroll } from 'react-scroll';
import DatePicker from 'react-datepicker';
//import "react-datepicker/dist/react-datepicker.css";
import api from '../services/api';
import { is } from 'date-fns/locale';


function Awn1() {
    const [isSubmitted, setIsSubmitted] = useState(false); // ✅ Track submission
    const [isSuccess, setIsSuccess] = useState(false); // ✅ Track submission
    
    const [submissionDate, setSubmissionDate] = useState(new Date());
    const [jobNumber, setJobNumber] = useState('');
    const [projectName, setProjectName] = useState('');
    const [clientName, setClientName] = useState('');
    const [projectType, setProjectType] = useState('');
    const [segmentName, setSegmentName] = useState('');
    const [researcherCharge, setResearcherCharge] = useState('');
    const [researcherCost, setResearcherCost] = useState('');
    const [finalQuestionnaire, setFinalQuestionnaire] = useState('');
    const [finalQuestionnaireChanged, setFinalQuestionnaireChanged] = useState(false);
    const [scirptingNeeded, setScirptingNeeded] = useState('');
    const [complexLogic, setComplexLogic] = useState('');
    const [sampleSize, setSampleSize] = useState('');
    const [dataCollection, setDataCollection] = useState('');
    const [haveS2CSub, setHaveS2CSub] = useState('');
    const [quota, setQuota] = useState('');
    const [quotaDetails, setQuotaDetails] = useState('');
    const [quotaDetailsChanged, setQuotaDetailsChanged] = useState('');
    const [expectedFieldDate, setExpectedFieldDate] = useState('');
    const [expectedFieldDateChanged, setExpectedFieldDateChanged] = useState('');
    const [analysisPlan, setAnalysisPlan] = useState('');
    const [analysisPlanChanged, setAnalysisPlanChanged] = useState('');
    const [firstCutDate, setFirstCutDate] = useState('');
    const [firstCutDateChanged, setFirstCutDateChanged] = useState('');
    const [dataWeightingRequired, setDataWeightingRequired] = useState('');
    const [sigTesting, setSigTesting] = useState('');
    const [spssOutput, setSpssOutput] = useState('');
    const [finalOutput, setFinalOutput] = useState('');
    const [finalOutputChanged, setFinalOutputChanged] = useState('');
    const [weightingMatrix, setWeightingMatrix] = useState('');
    const [weightingMatrixChanged, setWeightingMatrixChanged] = useState('');
    const [option1, setOption1] = useState(false);
    const [option2, setOption2] = useState(false);
    const [option3, setOption3] = useState(false);
    const [option4, setOption4] = useState(false);
    const [statementFF, setStatementFF] = useState('');
    const [maxdiffProgramming, setMaxdiffProgramming] = useState('');
    const [jaccards, setJaccards] = useState('');
    const [regression, setRegression] = useState('');
    const [krushal, setKrushal] = useState('');
    const [shapely, setShapely] = useState('');
    const [correlation, setCorrelation] = useState('');
    const [chaid, setChaid] = useState('');
    const [cluster, setCluster] = useState('');
    const [factor, setFactor] = useState('');
    const [bIP, setBIP] = useState('');
    const [dDV, setDDV] = useState('');
    const [dIV, setDIV] = useState('');
    const [numberOfAttributes, setNumberOfAttributes] = useState('');
    const [showAlert, setShowAlert] = useState(false);
    const [showAlert2, setShowAlert2] = useState(false);
    const [showAlert3, setShowAlert3] = useState(false);
    const [showNext, setShowNext] = useState(false);
    const [showNext2, setShowNext2] = useState(false);
    const navigate = useNavigate();
    const [errors, setErrors] = useState({});
    const[errorMessage,setErrorMessage]=useState('');
    useEffect(() => {
        if(isSuccess || showAlert || showAlert2 || showAlert3){
            scroll.scrollToTop({ smooth: true });
        }
    },[isSuccess,showAlert,showAlert2,showAlert3])
    const handleQuotaDetailsChange = (date) => {
        setQuotaDetails(date);
        setQuotaDetailsChanged(true);
      };

      const handleExpectedFieldChange = (date1) => {
        setExpectedFieldDate(date1);
        setExpectedFieldDateChanged(true);
      };

      const handleAnalysisChange = (date2) => {
        setAnalysisPlan(date2);
        setAnalysisPlanChanged(true);
      };

      const handleFirstCutChange = (date3) => {
        setFirstCutDate(date3);
        setFirstCutDateChanged(true);
      };

      const handleFinalOutputChange = (date4) => {
        setFinalOutput(date4);
        setFinalOutputChanged(true);
      };

      const handleWeightingMatrixChange = (date5) => {
        setWeightingMatrix(date5);
        setWeightingMatrixChanged(true);
      };

      const handleFinalQuestionnaireChange = (date6) => {
        setFinalQuestionnaire(date6);
        setFinalQuestionnaireChanged(true);
      };

    const handleSampleSizeChange = (e) => {
        const value = e.target.value;
        if (/^\d*$/.test(value)) {
            setSampleSize(value);
        }
    };

    const handleNoaChange = (e) => {
        const value1 = e.target.value;
        if (/^\d*$/.test(value1)) {
            setNumberOfAttributes(value1);
        }
    };

    // const handleSubmit = async () => {
    //     // Reset previous errors
    //     setErrorMessage('Please fill all mandatory fields!');
    //     const newErrors = {};
    //     let errorMessage = '';
    
    //     // Required fields validation
    //     if (!submissionDate) {
    //         newErrors.submissionDate = 'Submission Date is required';
    //         errorMessage = 'Submission Date is required. ';
    //     }
    //     if (!jobNumber.trim()) {
    //         newErrors.jobNumber = 'Job Number is required';
    //         errorMessage = 'Job Number is required. ';
    //     }
    //     if (!projectName.trim()) {
    //         newErrors.projectName = 'Project Name is required';
    //         errorMessage = 'Project Name is required. ';
    //     }

        
        
    //     if (!clientName.trim()) {
    //         newErrors.clientName = 'client Name is required';
    //         errorMessage = 'Project Name is required. ';
    //     }
    //     if (!segmentName.trim()) {
    //         newErrors.segmentName = 'Segment Name is required';
    //         errorMessage = 'Segment Name is required. ';
    //     }
        

    //     if (!sampleSize.trim()) {
    //         newErrors.sampleSize = 'Sample size is required';
    //         errorMessage = 'Sample size is required. ';
    //     }
    //     if (!researcherCost.trim()) {
    //         newErrors.researcherCost = 'Researcher Cost Center is required';
    //         errorMessage = 'Researcher Cost Center is required. ';
    //     }
    
    //     // If any errors exist, show alert and update error state.
    //     if (Object.keys(newErrors).length > 0) {
    //         setErrors(newErrors);
    //         setErrorMessage(errorMessage.trim()); // Set complete error message
    //         console.log("Validation Errors:", newErrors);
    //         setShowAlert(true);
    //         scroll.scrollToTop(); // Scroll to top for visibility
    //         return;
    //     } else {
    //         setErrors({});
    //         setShowAlert(false);
    //     }
    
    //     // ✅ Ensure Dates are formatted as YYYY-MM-DD safely
    //     const formatDate = (date) => {
    //         if (!date) return null;
    //         if (date instanceof Date) return date.toISOString().split('T')[0];
    //         if (typeof date === 'string' && date.match(/^\d{4}-\d{2}-\d{2}$/)) return date;
    //         try {
    //             return new Date(date).toISOString().split('T')[0];
    //         } catch (e) {
    //             console.error("Invalid date format:", date);
    //             return null;
    //         }
    //     };
    
    //     // ✅ Create segment payload
    //     const segmentPayload = {
    //         date_of_awn: formatDate(submissionDate),
    //         job_number: jobNumber,
    //         project_name: projectName,
    //         unit: researcherCost,
    //         segment_name: segmentName,
    //         actual_sample_size:sampleSize,
    //         client: clientName,
    //         expected_date_of_final_output: formatDate(finalOutput),
    //     };
    
    //     try {
    //         // 🔹 Step 1: Create segment entry
    //         console.log("Submitting segmentPayload:", segmentPayload);
    //         const segmentResponse = await api.post('api/awn/create-segment-entry/', segmentPayload);
    
    //         if (![201, 200].includes(segmentResponse.status)) {
    //             throw new Error(`Failed to create segment: ${segmentResponse.statusText}`);
    //         }
    
    //         const segmentId = segmentResponse.data.segment_id;
    //         console.log("Segment Created Successfully:", segmentId);
    //         setIsSubmitted(true);
            
    //         // 🔹 Step 2: Prepare AwnEntry payload with properly formatted dates
    //         const awnPayload = {
    //             submissionDate: formatDate(submissionDate),
    //             jobNumber,
    //             projectName,
    //             segmentName,
    //             clientName,
    //             projectType,
    //             researcherCharge: researcherCharge ? parseInt(researcherCharge, 10) : null,
    //             researcherCost,
    //             finalQuestionnaire: formatDate(finalQuestionnaire),
    //             finalQuestionnaireChanged: formatDate(finalQuestionnaireChanged),
    //             scirptingNeeded: scirptingNeeded === "Yes",
    //             complexLogic: complexLogic === "Yes",
    //             sampleSize: sampleSize ? parseInt(sampleSize, 10) : 0,
    //             dataCollection,
    //             haveS2CSub: haveS2CSub === "Yes",
    //             quota: quota === "Yes",
    //             quotaDetails: formatDate(quotaDetails),
    //             quotaDetailsChanged: formatDate(quotaDetailsChanged) || null,
    //             expectedFieldDate: formatDate(expectedFieldDate) || null,
    //             expectedFieldDateChanged: formatDate(expectedFieldDateChanged) || null,
    //             analysisPlan: formatDate(analysisPlan),
    //             analysisPlanChanged: formatDate(analysisPlanChanged),
    //             firstCutDate: formatDate(firstCutDate),
    //             firstCutDateChanged: formatDate(firstCutDateChanged),
    //             dataWeightingRequired: dataWeightingRequired === "Yes",
    //             sigTesting: sigTesting === "Yes",
    //             spssOutput: spssOutput === "Yes",
    //             finalOutput: formatDate(finalOutput),
    //             finalOutputChanged: formatDate(finalOutputChanged),
    //             weightingMatrix: formatDate(weightingMatrix) || null,
    //             weightingMatrixChanged: formatDate(weightingMatrixChanged) || null,
    //             option1: option1 ?? false,
    //             option2: option2 ?? false,
    //             option3: option3 ?? false,
    //             option4: option4 ?? false,
    //             statementFF: statementFF === "Yes",
    //             maxdiffProgramming: maxdiffProgramming === "Yes",
    //             jaccards: jaccards === "Yes",
    //             regression: regression === "Yes",
    //             krushal: krushal === "Yes",
    //             shapely: shapely === "Yes",
    //             correlation: correlation === "Yes",
    //             chaid: chaid === "Yes",
    //             cluster: cluster === "Yes",
    //             factor: factor === "Yes",
    //             bIP: bIP === "Yes",
    //             dDV: dDV === "Yes",
    //             dIV: dIV === "Yes",
    //             numberOfAttributes: numberOfAttributes ? parseInt(numberOfAttributes, 10) : 0,
    //             segment: segmentId,
    //         };
    
    //         console.log("Submitting awnPayload:", awnPayload);
    
    //         // 🔹 Step 3: Create Awn Entry
    //         const awnResponse = await api.post('api/awn/entries/', awnPayload);
    
    //         if (![201, 200].includes(awnResponse.status)) {
    //             throw new Error('Failed to create awn entry');
    //         }
    
    //         console.log("Awn Entry Created Successfully:", awnResponse.data);
    //         setIsSuccess(true);
    //         // ✅ If everything is successful, navigate to the next page
    //         // navigate('/conclusion',{state: {isSubmitted: true}});
    
    //     } catch (error) {
    //         console.error("Error in Submission:", error);
    //         setShowAlert(true);
    //     }
    // };
    
    // const handleSubmit = async () => {
    //     // Reset previous errors
    //     setErrorMessage('');
    //     const newErrors = {};
    //     let errorMessage = '';
    
    //     // Required fields validation
    //     if (!submissionDate) {
    //         newErrors.submissionDate = 'Submission Date is required';
    //         errorMessage += 'Submission Date is required. ';
    //     }
    //     if (!jobNumber.trim()) {
    //         newErrors.jobNumber = 'Job Number is required';
    //         errorMessage += 'Job Number is required. ';
    //     }
    //     if (!projectName.trim()) {
    //         newErrors.projectName = 'Project Name is required';
    //         errorMessage += 'Project Name is required. ';
    //     }
    //     if (!clientName.trim()) {
    //         newErrors.clientName = 'Client Name is required';
    //         errorMessage += 'Client Name is required. ';
    //     }
    //     if (!segmentName.trim()) {
    //         newErrors.segmentName = 'Segment Name is required';
    //         errorMessage += 'Segment Name is required. ';
    //     }
    //     if (!sampleSize.trim()) {
    //         newErrors.sampleSize = 'Sample size is required';
    //         errorMessage += 'Sample size is required. ';
    //     }
    //     if (!researcherCost.trim()) {
    //         newErrors.researcherCost = 'Researcher Cost Center is required';
    //         errorMessage += 'Researcher Cost Center is required. ';
    //     }
    
    //     // If any validation errors exist, show alert and return
    //     if (Object.keys(newErrors).length > 0) {
    //         setErrors(newErrors);
    //         setErrorMessage(errorMessage.trim());
    //         console.log("Validation Errors:", newErrors);
    //         setShowAlert(true);
    //         scroll.scrollToTop();
    //         return;
    //     } else {
    //         setErrors({});
    //         setShowAlert(false);
    //     }
    
    //     // ✅ Ensure Dates are formatted as YYYY-MM-DD safely
    //     const formatDate = (date) => {
    //         if (!date) return null;
    //         if (date instanceof Date) return date.toISOString().split('T')[0];
    //         if (typeof date === 'string' && date.match(/^\d{4}-\d{2}-\d{2}$/)) return date;
    //         try {
    //             return new Date(date).toISOString().split('T')[0];
    //         } catch (e) {
    //             console.error("Invalid date format:", date);
    //             return null;
    //         }
    //     };
    
    //     // ✅ Create segment payload
    //     const segmentPayload = {
    //         date_of_awn: formatDate(submissionDate),
    //         job_number: jobNumber,
    //         project_name: projectName,
    //         unit: researcherCost,
    //         segment_name: segmentName,
    //         actual_sample_size: sampleSize,
    //         client: clientName,
    //         expected_date_of_final_output: formatDate(finalOutput),
    //     };
    
    //     try {
    //         // 🔹 Step 1: Create segment entry
    //         console.log("Submitting segmentPayload:", segmentPayload);
    //         const segmentResponse = await api.post('api/awn/create-segment-entry/', segmentPayload);
    
    //         if (![201, 200].includes(segmentResponse.status)) {
    //             throw new Error(`Failed to create segment: ${segmentResponse.statusText}`);
    //         }
    
    //         const segmentId = segmentResponse.data.segment_id;
    //         console.log("Segment Created Successfully:", segmentId);
    //         setIsSubmitted(true);
    
    //         // Check if a warning message exists and update errorMessage
    //         if (segmentResponse.data.warning) {
    //             errorMessage += segmentResponse.data.warning + " This will be added as an additional segment ";
    //         }
    
    //         // 🔹 Step 2: Prepare AwnEntry payload
    //         const awnPayload = {
    //             submissionDate: formatDate(submissionDate),
    //             jobNumber,
    //             projectName,
    //             segmentName,
    //             clientName,
    //             projectType,
    //             researcherCharge: researcherCharge ? parseInt(researcherCharge, 10) : null,
    //             researcherCost,
    //             finalQuestionnaire: formatDate(finalQuestionnaire),
    //             finalQuestionnaireChanged: formatDate(finalQuestionnaireChanged),
    //             scirptingNeeded: scirptingNeeded === "Yes",
    //             complexLogic: complexLogic === "Yes",
    //             sampleSize: sampleSize ? parseInt(sampleSize, 10) : 0,
    //             dataCollection,
    //             haveS2CSub: haveS2CSub === "Yes",
    //             quota: quota === "Yes",
    //             quotaDetails: formatDate(quotaDetails),
    //             quotaDetailsChanged: formatDate(quotaDetailsChanged) || null,
    //             expectedFieldDate: formatDate(expectedFieldDate) || null,
    //             expectedFieldDateChanged: formatDate(expectedFieldDateChanged) || null,
    //             analysisPlan: formatDate(analysisPlan),
    //             analysisPlanChanged: formatDate(analysisPlanChanged),
    //             firstCutDate: formatDate(firstCutDate),
    //             firstCutDateChanged: formatDate(firstCutDateChanged),
    //             dataWeightingRequired: dataWeightingRequired === "Yes",
    //             sigTesting: sigTesting === "Yes",
    //             spssOutput: spssOutput === "Yes",
    //             finalOutput: formatDate(finalOutput),
    //             finalOutputChanged: formatDate(finalOutputChanged),
    //             weightingMatrix: formatDate(weightingMatrix) || null,
    //             weightingMatrixChanged: formatDate(weightingMatrixChanged) || null,
    //             option1: option1 ?? false,
    //             option2: option2 ?? false,
    //             option3: option3 ?? false,
    //             option4: option4 ?? false,
    //             statementFF: statementFF === "Yes",
    //             maxdiffProgramming: maxdiffProgramming === "Yes",
    //             jaccards: jaccards === "Yes",
    //             regression: regression === "Yes",
    //             krushal: krushal === "Yes",
    //             shapely: shapely === "Yes",
    //             correlation: correlation === "Yes",
    //             chaid: chaid === "Yes",
    //             cluster: cluster === "Yes",
    //             factor: factor === "Yes",
    //             bIP: bIP === "Yes",
    //             dDV: dDV === "Yes",
    //             dIV: dIV === "Yes",
    //             numberOfAttributes: numberOfAttributes ? parseInt(numberOfAttributes, 10) : 0,
    //             segment: segmentId,
    //         };
    
    //         console.log("Submitting awnPayload:", awnPayload);
    
    //         // 🔹 Step 3: Create Awn Entry
    //         const awnResponse = await api.post('api/awn/entries/', awnPayload);
    
    //         if (![201, 200].includes(awnResponse.status)) {
    //             throw new Error('Failed to create awn entry');
    //         }
    
    //         console.log("Awn Entry Created Successfully:", awnResponse.data);
    //         setIsSuccess(true);
    
    //         // ✅ If there's a warning, set it as errorMessage
    //         if (errorMessage.trim()) {
    //             setErrorMessage(errorMessage.trim());
    //             setShowAlert(true);
    //             scroll.scrollToTop();
    //         }
    
    //     } catch (error) {
    //         console.error("Error in Submission:", error);
    //         setErrorMessage("An error occurred during submission. Please try again.");
    //         setShowAlert(true);
    //     }
    // };
    
    const handleSubmit = async () => {
        // Reset previous errors
        setErrorMessage('');
        const newErrors = {};
        let errorMessage = '';
    
        // ✅ Mandatory Field Validations
        const validateField = (field, fieldName) => {
            if (!fieldName) return; // Prevent undefined error
            if (!field || (typeof field === "string" && !field.trim())) {
                const formattedName = fieldName.replace(/([A-Z])/g, ' $1');
                newErrors[fieldName] = `${formattedName} is required`;
                errorMessage += `${formattedName} is required. `;
            }
        };
    
        // ✅ Required Fields Validation
        const requiredFields = [
            submissionDate, jobNumber, projectName, clientName, projectType, researcherCost, finalQuestionnaire,
            scirptingNeeded, researcherCharge, sampleSize, dataCollection, complexLogic, haveS2CSub, firstCutDate,
            dataWeightingRequired, sigTesting, finalOutput,  statementFF, spssOutput,
             expectedFieldDate, analysisPlan, maxdiffProgramming, jaccards, shapely, correlation,
            chaid, cluster, factor, bIP, regression, krushal, dDV, dIV
        ];
    
        const fieldNames = [
            'Submission Date', 'Job Number', 'Project Name', 'Client Name', 'Project Type', 'Researcher Cost Center',
            'Final Questionnaire', 'Scripting Needed', 'Researcher Charge', 'Sample Size', 'Data Collection', 'Complex Logic',
            'Have S2C Subscription', 'First Cut Date', 'Data Weighting Required', 'Sig Testing', 'Final Output', 
            'Statement FF', 'SPSS Output',  'Expected Field Date', 'Analysis Plan', 'MaxDiff Programming',
            'Jaccards', 'Shapely', 'Correlation', 'CHAID', 'Cluster', 'Factor', 'BIP', 'Regression', 'Krushal', 'DDV', 'DIV'
        ];
    
        requiredFields.forEach((field, index) => validateField(field, fieldNames[index]));
    
        // ✅ Numeric Fields Validation
        const validateNumericField = (field, fieldName) => {
            if (!field || isNaN(field) || parseInt(field, 10) <= 0) {
                const formattedName = fieldName.replace(/([A-Z])/g, ' $1');
                newErrors[fieldName] = `${formattedName} must be a positive number`;
                errorMessage += `${formattedName} must be a positive number. `;
            }
        };
    
        validateNumericField(sampleSize, 'Sample Size');
        if (numberOfAttributes) validateNumericField(numberOfAttributes, 'Number of Attributes');
    
        // ✅ Ensure at least one Option is selected
        if (sigTesting==='Yes' && (!option1 && !option2 && !option3 && !option4)) {
            newErrors.options = 'At least one option must be selected';
            errorMessage += 'At least one option must be selected. ';
        }
    
        // ✅ Boolean Fields Validation
        const validateBooleanField = (field, fieldName) => {
            if (field !== "Yes" && field !== "No") {
                const formattedName = fieldName.replace(/([A-Z])/g, ' $1');
                newErrors[fieldName] = `${formattedName} must be selected`;
                errorMessage += `${formattedName} must be selected. `;
            }
        };
    
        const booleanFields = [
            scirptingNeeded, complexLogic, quota, haveS2CSub, dataWeightingRequired, sigTesting,
            spssOutput, maxdiffProgramming, jaccards, shapely, correlation, chaid, cluster, factor,
            bIP, regression, krushal 
        ];
    
        const booleanFieldNames = [
            'Scripting Needed', 'Complex Logic', 'Quota', 'Have S2C Subscription', 'Data Weighting Required',
            'Sig Testing', 'SPSS Output', 'MaxDiff Programming', 'Jaccards', 'Shapely', 'Correlation', 'CHAID',
            'Cluster', 'Factor', 'BIP', 'Regression', 'Krushal' 
        ];
    
        booleanFields.forEach((field, index) => validateBooleanField(field, booleanFieldNames[index]));
    
        // ✅ Date Fields Validation
        const validateDate = (date, fieldName) => {
            if (date && isNaN(Date.parse(date))) {
                const formattedName = fieldName.replace(/([A-Z])/g, ' $1');
                newErrors[fieldName] = `${formattedName} must be a valid date`;
                errorMessage += `${formattedName} must be a valid date. `;
            }
        };
    
        const dateFields = [
            finalQuestionnaire, quotaDetails, expectedFieldDate, analysisPlan, firstCutDate, finalOutput
        ];
    
        const dateFieldNames = [
            'Final Questionnaire', 'Quota Details', 'Expected Field Date', 'Analysis Plan', 'First Cut Date', 'Final Output'
        ];
    
        dateFields.forEach((field, index) => validateDate(field, dateFieldNames[index]));
    
        // ✅ If validation fails, show errors and return
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            setErrorMessage(errorMessage.trim());
            console.log("Validation Errors:", newErrors);
            setShowAlert(true);
            scroll.scrollToTop();
            return;
        } else {
            setErrors({});
            setShowAlert(false);
        }
    
        // ✅ Ensure Dates are formatted as YYYY-MM-DD safely
        const formatDate = (date) => {
            if (!date) return null;
            if (date instanceof Date) return date.toISOString().split('T')[0];
            if (typeof date === 'string' && date.match(/^\d{4}-\d{2}-\d{2}$/)) return date;
            try {
                return new Date(date).toISOString().split('T')[0];
            } catch (e) {
                console.error("Invalid date format:", date);
                return null;
            }
        };
    
        // ✅ Create segment payload
        const segmentPayload = {
            date_of_awn: formatDate(submissionDate),
            job_number: jobNumber,
            project_name: projectName,
            unit: researcherCost,
            segment_name: segmentName,
            actual_sample_size: sampleSize,
            client: clientName,
            expected_date_of_final_output: formatDate(finalOutput),
        };
    
        try {
            console.log("Submitting segmentPayload:", segmentPayload);
            const segmentResponse = await api.post('api/awn/create-segment-entry/', segmentPayload);
    
            if (![201, 200].includes(segmentResponse.status)) {
                throw new Error(`Failed to create segment: ${segmentResponse.statusText}`);
            }
    
            const segmentId = segmentResponse.data.segment_id;
            console.log("Segment Created Successfully:", segmentId);
            setIsSubmitted(true);
    
            if (segmentResponse.data.warning) {
                errorMessage += segmentResponse.data.warning + " This will be added as an additional segment ";
            }
    
            if (errorMessage.trim()) {
                setErrorMessage(errorMessage.trim());
                setShowAlert(true);
                scroll.scrollToTop();
            }
    
        } catch (error) {
            console.error("Error in Submission:", error);
            setErrorMessage("An error occurred during submission. Please try again.");
            setShowAlert(true);
        }
    };
    
    
    const handleDownload = async () => {
              {
        setShowAlert3(false);
        //alert("3")
        const response = await fetch('/4SiGHT_Analysis_Warning_Note.xlsx');
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const arrayBuffer = await response.arrayBuffer();

        const workbook = new Workbook();
        await workbook.xlsx.load(arrayBuffer);
        const worksheet = workbook.worksheets[0];

        const selectedOptions = [];
        if (option1) selectedOptions.push('☑95%');
        if (option2) selectedOptions.push('☑90%');
        if (option3) selectedOptions.push('☑80%');
        if (option4) selectedOptions.push('☑67%');        
        const selectedOptionsString = selectedOptions.join(', ');
        const formatDate = (date) => {
            if (!date) return '';
            const options = { day: '2-digit', month: 'long', year: 'numeric' };
            return new Date(date).toLocaleDateString('en-GB', options);
        };

        const setCellValueAndAlignRight = (cell, value) => {
            worksheet.getCell(cell).value = value;
            worksheet.getCell(cell).alignment = { horizontal: 'right' };
        };

        worksheet.getCell('C6').value = submissionDate;
        worksheet.getCell('C7').value = jobNumber;
        worksheet.getCell('C8').value = projectName;
        worksheet.getCell('C12').value = clientName;
        worksheet.getCell('C9').value = projectType;
        worksheet.getCell('C10').value = researcherCharge;
        worksheet.getCell('C11').value = researcherCost;
        setCellValueAndAlignRight('C14', formatDate(finalQuestionnaire));
        worksheet.getCell('C15').value = scirptingNeeded;
        worksheet.getCell('F15').value = complexLogic;
        worksheet.getCell('C16').value = sampleSize;
        worksheet.getCell('C17').value = dataCollection;
        worksheet.getCell('C18').value = haveS2CSub;
        setCellValueAndAlignRight('C26', formatDate(firstCutDate));
        worksheet.getCell('C28').value = dataWeightingRequired;
        worksheet.getCell('C30').value = sigTesting;
        worksheet.getCell('C32').value = spssOutput;
        setCellValueAndAlignRight('F26', formatDate(finalOutput));
        setCellValueAndAlignRight('F28', formatDate(weightingMatrix));
        worksheet.getCell('F32').value = statementFF;
        worksheet.getCell('C19').value = quota;
        setCellValueAndAlignRight('C20', formatDate(quotaDetails));
        setCellValueAndAlignRight('C21', formatDate(expectedFieldDate));
        setCellValueAndAlignRight('C22', formatDate(analysisPlan));
        worksheet.getCell('E30').value = selectedOptionsString;
        worksheet.getCell('C35').value = maxdiffProgramming;
        worksheet.getCell('C37').value = numberOfAttributes;
        worksheet.getCell('C42').value = jaccards;
        worksheet.getCell('C43').value = regression;
        worksheet.getCell('C44').value = krushal;
        worksheet.getCell('C45').value = shapely;
        worksheet.getCell('C46').value = correlation;
        worksheet.getCell('C47').value = chaid;
        worksheet.getCell('C49').value = cluster;
        worksheet.getCell('C50').value = factor;
        worksheet.getCell('C51').value = bIP;
        worksheet.getCell('F42').value = dDV;
        worksheet.getCell('F44').value = dIV;

        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(blob, `4SiGHT_Analysis_Warning Note_${projectName}.xlsx`);
        navigate('/');
    }
    };

        const handleNext = () => {
            if (maxdiffProgramming && jaccards && shapely && correlation && chaid && cluster && factor && bIP &&
                regression && krushal && dDV && dIV && numberOfAttributes && submissionDate && jobNumber &&
                 projectName && clientName && projectType && researcherCharge && researcherCost &&
                finalQuestionnaire &&  scirptingNeeded && sampleSize && dataCollection  && 
                 complexLogic
                && dataCollection && haveS2CSub && firstCutDate && dataWeightingRequired && sigTesting
                 && finalOutput && weightingMatrix && statementFF && 
                spssOutput && quotaDetails && 
            expectedFieldDate && analysisPlan && (option1 || option2 || option3 || option4)
            ) {
                navigate('/conclusion');
                setShowAlert3(false);
            } else {
                setShowAlert3(true);
            }
        };

        const handleJob = (e) => {
            const value = e.target.value;
            const regex = /^[A-Za-z]{0,2}$|^[A-Za-z]{2}\d*$/;

            if (regex.test(value)) {
                setJobNumber(value);
            } else {
            }
        };

    
   
    return (
        <div>
        
        <br />
        {isSubmitted && ( // ✅ Show button only after successful submission
               
               <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px' }}>
               <Alert variant="success" onClose={() => setIsSuccess(false)} dismissible>
          AWN Entry Submitted Successfully! Please Download AWN!
        </Alert>
                   <Button className='awnB' onClick={handleDownload} variant="success">
                       Download AWN
                   </Button>
               </div>
           )}
        <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
        <div className="d-flex flex-column justify-content-center p-4 custom-class" style={{
        backgroundColor: 'white', backdropFilter: 'blur(15px)', color:'black', 
        border:'1px solid lightgrey', width:'95%'}}>
        {showAlert && (
        <Alert variant="danger" onClose={() => setShowAlert(false)} dismissible>
          {errorMessage}
        </Alert>
      )}
        <Form>
        <Row>
        <Col md = "6">
        <Form.Group as={Row} style={{marginBottom:'20px'}}>
            <Form.Label column sm="6" style={{color:'black'
            ,  fontFamily: 'Roboto, sans-serif', backgroundColor:'#e8e3ec', borderRadius:'5px'}}>Submission Date:</Form.Label>
            <Col sm="6">
                <DatePicker
                    selected={submissionDate}
                    onChange={(date) => setSubmissionDate(date)}
                    dateFormat="dd/MM/yyyy"
                    className="custom-date-picker form-control"
                    disabled
                />
            </Col>
        </Form.Group>
        <Form.Group as={Row} style={{marginBottom:'20px'}}>
            <Form.Label column sm="6" style={{color:'black'
            ,  fontFamily: 'Roboto, sans-serif', backgroundColor:'#e8e3ec', borderRadius:'5px'}}>Job Number:</Form.Label>
            <Col sm="6">
            <Form.Control 
            type="text"
            value={jobNumber}
            onChange={handleJob}
            style={{width:'100%', backgroundColor:'#F1F1F1'}}
             />
             </Col>
        </Form.Group>
        <Form.Group as={Row} style={{marginBottom:'20px'}}>
            <Form.Label column sm="6" style={{color:'black'
            ,  fontFamily: 'Roboto, sans-serif', backgroundColor:'#e8e3ec', borderRadius:'5px'}}>Project Name:</Form.Label>
            <Col sm="6">
            <Form.Control 
            type="text"
            value={projectName}
            onChange={(e) => setProjectName(e.target.value)}
            style={{width:'100%', backgroundColor:'#F1F1F1'}}
             />
             </Col>
        </Form.Group>

        


        <Form.Group as={Row} style={{marginBottom:'20px'}}>
            <Form.Label column sm="6" style={{color:'black'
            ,  fontFamily: 'Roboto, sans-serif', backgroundColor:'#e8e3ec', borderRadius:'5px'}}>Project Type:</Form.Label>
            {/* <Form.Label column sm="7" style={{color:'#8EA7E9'}}>Project Type:</Form.Label> */}
            <Col sm="6">
            <Form.Control 
            type="text"
            value={projectType}
            onChange={(e) => setProjectType(e.target.value)}
            style={{width:'100%', backgroundColor:'#F1F1F1'}}
             />
             </Col>
        </Form.Group>
        <Form.Group as={Row} style={{marginBottom:'20px'}}>
            <Form.Label column sm="6" style={{color:'black'
            ,  fontFamily: 'Roboto, sans-serif', backgroundColor:'#e8e3ec', borderRadius:'5px'}}>Researcher In Charge:</Form.Label>
            <Col sm="6">
            <Form.Control 
            type="text"
            value={researcherCharge}
            onChange={(e) => setResearcherCharge(e.target.value)}
            style={{width:'100%', backgroundColor:'#F1F1F1'}}
             />
             </Col>
        </Form.Group>
        <Form.Group as={Row}>
            <Form.Label column sm="6" style={{color:'black'
            ,  fontFamily: 'Roboto, sans-serif', backgroundColor:'#e8e3ec', borderRadius:'5px'}}>Researcher Cost Center(KSA/UAE):</Form.Label>
            <Col sm="6">
            <Form.Control 
            as="select"
            value={researcherCost}
            onChange={(e) => setResearcherCost(e.target.value)}
            style={{width:'100%', backgroundColor:'#F1F1F1'}}
             >
             <option value hidden>KSA/UAE</option>
             <option value = "4SIGHT KSA">4SIGHT KSA</option>
             <option value = "4SIGHT UAE">4SIGHT UAE</option>
             </Form.Control>
             </Col>
        </Form.Group>
        </Col>
        <Col md = "6">
        <Form.Group as={Row} style={{marginBottom:'20px'}}>

        <Form.Group as={Row} style={{marginBottom:'20px'}}>
            <Form.Label column sm="6" style={{color:'black'
            ,  fontFamily: 'Roboto, sans-serif', backgroundColor:'#e8e3ec', borderRadius:'5px'}}>Segment Name:</Form.Label>
            <Col sm="6">
            <Form.Control 
            type="text"
            value={segmentName}
            onChange={(e) => setSegmentName(e.target.value)}
            style={{width:'100%', backgroundColor:'#F1F1F1'}}
             />
             </Col>
        </Form.Group>
        <Form.Group as={Row} style={{marginBottom:'20px'}}>
            <Form.Label column sm="6" style={{color:'black'
            ,  fontFamily: 'Roboto, sans-serif', backgroundColor:'#e8e3ec', borderRadius:'5px'}}>Client Name:</Form.Label>
            <Col sm="6">
            <Form.Control 
            type="text"
            value={clientName}
            onChange={(e) => setClientName(e.target.value)}
            style={{width:'100%', backgroundColor:'#F1F1F1'}}
             />
             </Col>
        </Form.Group>
            <Form.Label column sm="6" style={{color:'black'
            ,  fontFamily: 'Roboto, sans-serif', backgroundColor:'#e8e3ec', borderRadius:'5px'}}>Final Questionnaire Will Be Provided On:</Form.Label>
            <Col sm="6">
             <DatePicker
                    selected={finalQuestionnaire}
                    onChange={handleFinalQuestionnaireChange}
                    dateFormat="dd/MM/yyyy"
                    className="custom-date-picker form-control"
                    onKeyDown={(e) => e.preventDefault()} 
                />
             </Col>
        </Form.Group>
        <Form.Group as={Row} style={{marginBottom:'20px'}}>
            <Form.Label column sm="6" style={{color:'black'
            ,  fontFamily: 'Roboto, sans-serif', backgroundColor:'#e8e3ec', borderRadius:'5px'}}>Scripting Needed:</Form.Label>
            <Col sm="6">
            <Form.Control 
            as="select"
            value={scirptingNeeded}
            onChange={(e) => setScirptingNeeded(e.target.value)}
            style={{width:'100%', backgroundColor:'#F1F1F1'}}
             >
             <option value hidden>Yes/No</option>
             <option value = "Yes">Yes</option>
             <option value = "No">No</option>
             </Form.Control>
             </Col>
        </Form.Group>
        
        <Form.Group as={Row} style={{marginBottom:'20px'}}>
            <Form.Label column sm="6" style={{color:'black'
            ,  fontFamily: 'Roboto, sans-serif', backgroundColor:'#e8e3ec', borderRadius:'5px'}}>If Yes, does it have any Complex Logic*</Form.Label>
            <Col sm="6">
            <Form.Control 
            as="select"
            value={complexLogic}
            onChange={(e) => setComplexLogic(e.target.value)}
            style={{width:'100%', backgroundColor:'#F1F1F1'}}
             >
             <option value hidden>Yes/No</option>
             <option value = "Yes">Yes</option>
             <option value = "No">No</option>
             </Form.Control>
             </Col>
        </Form.Group>
        <Form.Group as={Row} style={{marginBottom:'20px'}}>
            <Form.Label column sm="6" style={{color:'black'
            ,  fontFamily: 'Roboto, sans-serif', backgroundColor:'#e8e3ec', borderRadius:'5px'}}>Sample Size:</Form.Label>
            <Col sm="6">
            <Form.Control 
            type="text"
            value={sampleSize}
            onChange={handleSampleSizeChange}
            style={{width:'100%',backgroundColor:'#F1F1F1'}}
             />
             </Col>
        </Form.Group>
        <Form.Group as={Row} style={{marginBottom:'20px'}}>
            <Form.Label column sm="6" style={{color:'black'
            ,  fontFamily: 'Roboto, sans-serif', backgroundColor:'#e8e3ec', borderRadius:'5px'}}>Data Collection Method(CAPI/CAWI):</Form.Label>
            <Col sm="6">
            <Form.Control 
            as = "select"
            value={dataCollection}
            onChange={(e) => setDataCollection(e.target.value)}
            style={{width:'100%', backgroundColor:'#F1F1F1'}}
             >
             <option value hidden>CAPI/CAWI</option>
             <option value = "CAPI">CAPI</option>
             <option value = "CAWI">CAWI</option>
             <option value = "Online">Online</option>
             <option value = "Database">Database</option>
             </Form.Control>
             </Col>
        </Form.Group>
        
        </Col>
        </Row>
       
        </Form>
        </div>
        </div>
    
        <div>
        <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
        <div className="d-flex flex-column justify-content-center p-4 custom-class" style={{
        backgroundColor: 'white', backdropFilter: 'blur(15px)', color:'black', 
        border:'1px solid lightgrey', width:'95%'}}>
        
        <Form>
        <Row>
        <Col md = "6">
        <Form.Group as={Row} style={{marginBottom:'20px'}}>
            <Form.Label column sm="6" style={{color:'black'
            ,  fontFamily: 'Roboto, sans-serif', backgroundColor:'#e8e3ec', borderRadius:'5px'}}>Have S2C Subscription:</Form.Label>
            <Col sm="6">
            <Form.Control 
            as = "select"
            value={haveS2CSub}
            onChange={(e) => setHaveS2CSub(e.target.value)}
            style={{width:'100%', backgroundColor:'#F1F1F1'}}
             >
             <option value hidden>Yes/No</option>
             <option value = "Yes">Yes</option>
             <option value = "No">No</option>
             </Form.Control>
             </Col>
        </Form.Group>
        <Form.Group as={Row} style={{marginBottom:'20px'}}>
            <Form.Label column sm="6" style={{color:'black'
            ,  fontFamily: 'Roboto, sans-serif', backgroundColor:'#e8e3ec', borderRadius:'5px'}}>Quota:</Form.Label>
            {/* <Form.Label column sm="7" style={{color:'#D862BC','#5e72e4'}}>Quota:</Form.Label> */}
            <Col sm="6">
            <Form.Control 
            as = "select"
            value={quota}
            onChange={(e) => setQuota(e.target.value)}
            style={{width:'100%', backgroundColor:'#F1F1F1'}}
             >
             <option value hidden>Yes/No</option>
             <option value = "Yes">Yes</option>
             <option value = "No">No</option>
             </Form.Control>
             </Col>
        </Form.Group>
        <Form.Group as={Row} style={{marginBottom:'20px'}}>
            <Form.Label column sm="6" style={{color:'black'
            ,  fontFamily: 'Roboto, sans-serif', backgroundColor:'#e8e3ec', borderRadius:'5px'}}>
            Quota/Launch Details will be Provided On:</Form.Label>
            <Col sm="6">
             <DatePicker
                    selected={quotaDetails}
                    onChange={handleQuotaDetailsChange}
                    dateFormat="dd/MM/yyyy"
                    className="custom-date-picker form-control"
                    disabled={quota !== "Yes"}
                    onKeyDown={(e) => e.preventDefault()} 
                />
             </Col>
        </Form.Group>
        <Form.Group as={Row} style={{marginBottom:'20px'}}>
            <Form.Label column sm="6" style={{color:'black'
            ,  fontFamily: 'Roboto, sans-serif', backgroundColor:'#e8e3ec', borderRadius:'5px'}}>
            Expected Field Work Completion Date:</Form.Label>
            <Col sm="6">
             <DatePicker
                    selected={expectedFieldDate}
                    onChange={handleExpectedFieldChange}
                    dateFormat="dd/MM/yyyy"
                    className="custom-date-picker form-control"
                    onKeyDown={(e) => e.preventDefault()} 
                />
             </Col>
        </Form.Group>
        <Form.Group as={Row} style={{marginBottom:'20px'}}>
            <Form.Label column sm="6" style={{color:'black'
            ,  fontFamily: 'Roboto, sans-serif', backgroundColor:'#e8e3ec', borderRadius:'5px'}}>Analysis Plan/Top Break will be Provided On:</Form.Label>
            <Col sm="6">
             <DatePicker
                    selected={analysisPlan}
                    onChange={handleAnalysisChange}
                    dateFormat="dd/MM/yyyy"
                    className="custom-date-picker form-control"
                    onKeyDown={(e) => e.preventDefault()} 
                />
             </Col>
        </Form.Group>
        <Form.Group as={Row} style={{marginBottom:'20px'}}>
            <Form.Label column sm="6" style={{color:'black'
            ,  fontFamily: 'Roboto, sans-serif', backgroundColor:'#e8e3ec', borderRadius:'5px'}}>First Cut/Mid Sample Date:</Form.Label>
            <Col sm="6">
             <DatePicker
                    selected={firstCutDate}
                    onChange={handleFirstCutChange}
                    dateFormat="dd/MM/yyyy"
                    className="custom-date-picker form-control"
                    onKeyDown={(e) => e.preventDefault()} 
                />
             </Col>
        </Form.Group>
        </Col>
        <Col md ="6">
        <Form.Group as={Row} style={{marginBottom:'10px'}}>
            <Form.Label column sm="6" style={{color:'black'
            ,  fontFamily: 'Roboto, sans-serif', backgroundColor:'#e8e3ec', borderRadius:'5px'}}>Do you need statement fixed format (if applicable)</Form.Label>
            <Col sm="6">
            <Form.Control 
            as="select"
            value={statementFF}
            onChange={(e) => setStatementFF(e.target.value)}
            style={{width:'100%', backgroundColor:'#F1F1F1'}}
             >
             <option value hidden>Yes/No</option>
             <option value = "Yes">Yes</option>
             <option value = "No">No</option>
             </Form.Control>
             </Col>
        </Form.Group>
        <Form.Group as={Row} style={{marginBottom:'20px'}}>
            <Form.Label column sm="6" style={{color:'black'
            ,  fontFamily: 'Roboto, sans-serif', backgroundColor:'#e8e3ec', borderRadius:'5px'}}>Sig Testing:</Form.Label>
            <Col sm="6">
            <Form.Control 
            as = "select"
            value={sigTesting}
            onChange={(e) => setSigTesting(e.target.value)}
            style={{width:'100%', backgroundColor:'#F1F1F1'}}
             >
             <option value hidden>Yes/No</option>
             <option value = "Yes">Yes</option>
             <option value = "No">No</option>
             </Form.Control>
             </Col>
        </Form.Group> 
        <Form.Group style={{marginBottom:'20px'}}>
        <Row>
            <Form.Label column sm="6" style={{color:'black'
            ,  fontFamily: 'Roboto, sans-serif', backgroundColor:'#e8e3ec', borderRadius:'5px'}}>Sig Testing Check:</Form.Label>
            <Col sm="6" style={{padding:'2px'}}>
            <Row>
                <Col>
                <Form.Check
                    type="checkbox"
                    label="95%"
                    name="option1"
                    checked={option1}
                    onChange={(e) => setOption1(e.target.checked)}
                    disabled={sigTesting !== "Yes"}
                />
                </Col>
                <Col>
                <Form.Check
                    type="checkbox"
                    label="90%"
                    name="option2"
                    checked={option2}
                    onChange={(e) => setOption2(e.target.checked)}
                    disabled={sigTesting !== "Yes"}
                />
                </Col>
                <Col>
                <Form.Check
                    type="checkbox"
                    label="80%"
                    name="option3"
                    checked={option3}
                    onChange={(e) => setOption3(e.target.checked)}
                    disabled={sigTesting !== "Yes"}
                />
                </Col>
                <Col>
                <Form.Check
                    type="checkbox"
                    label="67%"
                    name="option4"
                    checked={option4}
                    onChange={(e) => setOption4(e.target.checked)}
                    disabled={sigTesting !== "Yes"}
                />
                </Col>
            </Row>
            </Col>
        </Row>
        </Form.Group>
        <Form.Group as={Row} style={{marginBottom:'20px'}}>
            <Form.Label column sm="6" style={{color:'black'
            ,  fontFamily: 'Roboto, sans-serif', backgroundColor:'#e8e3ec', borderRadius:'5px'}}>SPSS OUTPUT:</Form.Label>
            <Col sm="6">
            <Form.Control 
            as = "select"
            value={spssOutput}
            onChange={(e) => setSpssOutput(e.target.value)}
            style={{width:'100%', backgroundColor:'#F1F1F1'}}
             >
             <option value hidden>Yes/No</option>
             <option value = "Yes">Yes</option>
             <option value = "No">No</option>
             </Form.Control>
             </Col>
        </Form.Group>
        <Form.Group as={Row} style={{marginBottom:'20px'}}>
            <Form.Label column sm="6" style={{color:'black'
            ,  fontFamily: 'Roboto, sans-serif', backgroundColor:'#e8e3ec', borderRadius:'5px'}}>Final Output (Date):</Form.Label>
            <Col sm="6">
             <DatePicker
                    selected={finalOutput}
                    onChange={handleFinalOutputChange}
                    dateFormat="dd/MM/yyyy"
                    className="custom-date-picker form-control"
                    onKeyDown={(e) => e.preventDefault()} 
                />
             </Col>
        </Form.Group>
        <Form.Group as={Row} style={{marginBottom:'20px'}}>
            <Form.Label column sm="6" style={{color:'black'
            ,  fontFamily: 'Roboto, sans-serif', backgroundColor:'#e8e3ec', borderRadius:'5px'}}>Data Weighting Required:</Form.Label>
            <Col sm="6">
            <Form.Control 
            as="select"
            value={dataWeightingRequired}
            onChange={(e) => setDataWeightingRequired(e.target.value)}
            style={{width:'100%', backgroundColor:'#F1F1F1'}}
             >
             <option value hidden>Yes/No</option>
             <option value = "Yes">Yes</option>
             <option value = "No">No</option>
             </Form.Control>
             </Col>
        </Form.Group>
        <Form.Group as={Row} style={{marginBottom:'20px'}}>
            <Form.Label column sm="6" style={{color:'black'
            ,  fontFamily: 'Roboto, sans-serif', backgroundColor:'#e8e3ec', borderRadius:'5px'}}>Weighting Matrix Will be Provided on:</Form.Label>
            <Col sm="6">
             <DatePicker
                    selected={weightingMatrix}
                    onChange={handleWeightingMatrixChange}
                    dateFormat="dd/MM/yyyy"
                    className="custom-date-picker form-control"
                    disabled={dataWeightingRequired !== "Yes"}
                    onKeyDown={(e) => e.preventDefault()} 
                />
             </Col>
        </Form.Group>
        </Col>
        </Row>
       
        </Form>
        </div>
        </div>
        </div>
    

        <div>
        <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
        <div className="d-flex flex-column justify-content-center p-4 custom-class" style={{
        backgroundColor: 'white', backdropFilter: 'blur(15px)', color:'black', 
        border:'1px solid lightgrey', width:'95%'}}>
       
        <Form>
        <Row>
        <Col md = "6">
        <Form.Group as={Row} style={{marginBottom:'20px'}}>
            <Form.Label column sm="6" style={{color:'black'
            ,  fontFamily: 'Roboto, sans-serif', backgroundColor:'#e8e3ec', borderRadius:'5px'}}>MAXDIFF Programming:</Form.Label>
            <Col sm="6">
            <Form.Control 
            as="select"
            value={maxdiffProgramming}
            onChange={(e) => setMaxdiffProgramming(e.target.value)}
            style={{width:'100%', backgroundColor:'#F1F1F1'}}
            // style={{width:'100%', backgroundColor:'#A0DEFF'}}
             >
             <option value hidden>Yes/No</option>
             <option value = "Yes">Yes</option>
             <option value = "No">No</option>
             </Form.Control>
             </Col>
        </Form.Group>
        <Form.Group as={Row} style={{marginBottom:'20px'}}>
            <Form.Label column sm="6" style={{color:'black'
            ,  fontFamily: 'Roboto, sans-serif', backgroundColor:'#e8e3ec', borderRadius:'5px'}}>Number of Attributes:</Form.Label>
            <Col sm="6">
            <Form.Control 
            type="text"
            value={numberOfAttributes}
            onChange={handleNoaChange}
            style={{width:'100%', backgroundColor:'#F1F1F1'}}
            disabled={maxdiffProgramming !== "Yes"}
             />
             </Col>
        </Form.Group>
        <Form.Group as={Row} style={{marginBottom:'20px'}}>
            <Form.Label column sm="6" style={{color:'black'
            ,  fontFamily: 'Roboto, sans-serif', backgroundColor:'#e8e3ec', borderRadius:'5px'}}>Jaccards:</Form.Label>
            <Col sm="6">
            <Form.Control 
            as="select"
            value={jaccards}
            onChange={(e) => setJaccards(e.target.value)}
            style={{width:'100%', backgroundColor:'#F1F1F1'}}
             >
             <option value hidden>Yes/No</option>
             <option value = "Yes">Yes</option>
             <option value = "No">No</option>
             </Form.Control>
             </Col>
        </Form.Group>
        <Form.Group as={Row} style={{marginBottom:'20px'}}>
            <Form.Label column sm="6" style={{color:'black'
            ,  fontFamily: 'Roboto, sans-serif', backgroundColor:'#e8e3ec', borderRadius:'5px'}}>Regression (Importance Score):</Form.Label>
            <Col sm="6">
            <Form.Control 
            as="select"
            value={regression}
            onChange={(e) => setRegression(e.target.value)}
            style={{width:'100%', backgroundColor:'#F1F1F1'}}
             >
             <option value hidden>Yes/No</option>
             <option value = "Yes">Yes</option>
             <option value = "No">No</option>
             </Form.Control>
             </Col>
        </Form.Group>
        <Form.Group as={Row} style={{marginBottom:'20px'}}>
            <Form.Label column sm="6" style={{color:'black'
            ,  fontFamily: 'Roboto, sans-serif', backgroundColor:'#e8e3ec', borderRadius:'5px'}}>Krushal (Importance Score):</Form.Label>
            <Col sm="6">
            <Form.Control 
            as="select"
            value={krushal}
            onChange={(e) => setKrushal(e.target.value)}
            style={{width:'100%', backgroundColor:'#F1F1F1'}}
             >
             <option value hidden>Yes/No</option>
             <option value = "Yes">Yes</option>
             <option value = "No">No</option>
             </Form.Control>
             </Col>
        </Form.Group>
            <Form.Group as={Row} style={{marginBottom:'20px'}}>
            <Form.Label column sm="6" style={{color:'black'
            ,  fontFamily: 'Roboto, sans-serif', backgroundColor:'#e8e3ec', borderRadius:'5px'}}>Shapely (Importance Score):</Form.Label>
            <Col sm="6">
            <Form.Control 
            as="select"
            value={shapely}
            onChange={(e) => setShapely(e.target.value)}
            style={{width:'100%', backgroundColor:'#F1F1F1'}}
             >
             <option value hidden>Yes/No</option>
             <option value = "Yes">Yes</option>
             <option value = "No">No</option>
             </Form.Control>
             </Col>
        </Form.Group>
        <Form.Group as={Row}>
            <Form.Label column sm="6" style={{color:'black'
            ,  fontFamily: 'Roboto, sans-serif', backgroundColor:'#e8e3ec', borderRadius:'5px'}}>Correlation (Partial):</Form.Label>
            <Col sm="6">
            <Form.Control 
            as="select"
            value={correlation}
            onChange={(e) => setCorrelation(e.target.value)}
            style={{width:'100%', backgroundColor:'#F1F1F1'}}
             >
             <option value hidden>Yes/No</option>
             <option value = "Yes">Yes</option>
             <option value = "No">No</option>
             </Form.Control>
             </Col>
        </Form.Group>
        </Col>
        <Col md = "6">
        <Form.Group as={Row} style={{marginBottom:'20px'}}>
            <Form.Label column sm="6" style={{color:'black'
            ,  fontFamily: 'Roboto, sans-serif', backgroundColor:'#e8e3ec', borderRadius:'5px'}}>CHAID:</Form.Label>
            <Col sm="6">
            <Form.Control 
            as="select"
            value={chaid}
            onChange={(e) => setChaid(e.target.value)}
            style={{width:'100%', backgroundColor:'#F1F1F1'}}
             >
             <option value hidden>Yes/No</option>
             <option value = "Yes">Yes</option>
             <option value = "No">No</option>
             </Form.Control>
             </Col>
        </Form.Group>
        <Form.Group as={Row} style={{marginBottom:'20px'}}>
            <Form.Label column sm="6" style={{color:'black'
            ,  fontFamily: 'Roboto, sans-serif', backgroundColor:'#e8e3ec', borderRadius:'5px'}}>Cluster:</Form.Label>
            <Col sm="6">
            <Form.Control 
            as="select"
            value={cluster}
            onChange={(e) => setCluster(e.target.value)}
            style={{width:'100%', backgroundColor:'#F1F1F1'}}
             >
             <option value hidden>Yes/No</option>
             <option value = "Yes">Yes</option>
             <option value = "No">No</option>
             </Form.Control>
             </Col>
        </Form.Group>
        <Form.Group as={Row} style={{marginBottom:'20px'}}>
            <Form.Label column sm="6" style={{color:'black'
            ,  fontFamily: 'Roboto, sans-serif', backgroundColor:'#e8e3ec', borderRadius:'5px'}}>Factor:</Form.Label>
            <Col sm="6">
            <Form.Control 
            as="select"
            value={factor}
            onChange={(e) => setFactor(e.target.value)}
            style={{width:'100%', backgroundColor:'#F1F1F1'}}
             >
             <option value hidden>Yes/No</option>
             <option value = "Yes">Yes</option>
             <option value = "No">No</option>
             </Form.Control>
             </Col>
        </Form.Group>
        <Form.Group as={Row} style={{marginBottom:'20px'}}>
            <Form.Label column sm="6" style={{color:'black'
            ,  fontFamily: 'Roboto, sans-serif', backgroundColor:'#e8e3ec', borderRadius:'5px'}}>BIP (Brand Image Profile):</Form.Label>
            <Col sm="6">
            <Form.Control 
            as="select"
            value={bIP }
            onChange={(e) => setBIP (e.target.value)}
            style={{width:'100%', backgroundColor:'#F1F1F1'}}
             >
             <option value hidden>Yes/No</option>
             <option value = "Yes">Yes</option>
             <option value = "No">No</option>
             </Form.Control>
             </Col>
        </Form.Group>
        <Form.Group as={Row} style={{marginBottom:'20px'}}>
            <Form.Label column sm="6" style={{color:'black', 
            fontFamily: 'Roboto, sans-serif', backgroundColor:'#e8e3ec', borderRadius:'5px'}}>Define Dependant Variable(If multiple - separate it by commas):</Form.Label>
            <Col sm="6">
            <Form.Control 
            type="text"
            value={dDV}
            onChange={(e) => setDDV(e.target.value)}
            style={{width:'100%', backgroundColor:'#F1F1F1'}}
             />
             </Col>
        </Form.Group>
        <Form.Group as={Row} style={{marginBottom:'20px'}}>
            <Form.Label column sm="6" style={{color:'black',
              fontFamily: 'Roboto, sans-serif', backgroundColor:'#e8e3ec', borderRadius:'5px'}}>Define Independent Variable(If multiple - separate it by commas):</Form.Label>
            <Col sm="6">
            <Form.Control 
            type="text"
            value={dIV}
            onChange={(e) => setDIV(e.target.value)}
            style={{width:'100%', backgroundColor:'#F1F1F1'}}
             />
             </Col>
        </Form.Group>
        <br />
                    </Col>
                </Row>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Button className='awnB' onClick={handleSubmit}>
          Submit
        </Button>
      </div>
            </Form>
                    
                </div>
                
            </div>
        <br />
        </div>
 
        </div>
    );
}

export default Awn1;
