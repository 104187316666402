import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  CircularProgress,
  Alert,
  Typography,
  Paper,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Avatar,
} from "@mui/material";
import { Comment as CommentIcon, ArrowForward as ArrowForwardIcon } from "@mui/icons-material";
import "./JTInbox.css";
import api from "../services/api";

const JTInbox = () => {
  const [unreadMessages, setUnreadMessages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  // Function to show a desktop notification
  const showNotification = (message) => {
    if (Notification.permission === "granted") {
      new Notification("New Message", {
        body: `${message.poster_name}: ${message.comment}`,
        icon: "/notification_icon.png", // Replace with a valid icon URL
      });
    }
  };

  useEffect(() => {
    let intervalId;

    // Request notification permission on component mount
    if (Notification.permission !== "granted") {
      Notification.requestPermission().then((permission) => {
        console.log("Notification permission:", permission);
      });
    }

    const fetchUnreadMessages = async () => {
      try {
        const username = localStorage.getItem("username");
        if (!username) {
          throw new Error("Username not found in local storage");
        }

        const response = await api.get(`discussion/project_unread_messages/?username=${username}`);
        const newMessages = response.data.unread_messages || [];

        // Check for new messages
        if (newMessages.length > unreadMessages.length) {
          showNotification(newMessages[0]); // Show notification for the latest message
        }

        setUnreadMessages(newMessages);
      } catch (error) {
        console.error("Error fetching unread messages:", error);
        setError("An error occurred while fetching unread messages.");
      } finally {
        setLoading(false);
      }
    };

    fetchUnreadMessages(); // Initial fetch

    // Fetch unread messages every 5 seconds
    intervalId = setInterval(() => {
      fetchUnreadMessages();
    }, 5000);

    // Cleanup function to clear the interval when component unmounts
    return () => clearInterval(intervalId);
  }, [unreadMessages]); // Dependency added to track updates

  const handleListItemClick = (segmentId) => {
    navigate("/discussion", { state: { segmentId } });
  };

  return (
    <Paper elevation={3} className="jt-inbox-container">
      <Typography variant="h4" gutterBottom className="inbox-header">
        Unread Messages
      </Typography>
      <Divider />

      {loading ? (
        <div className="loading-container">
          <CircularProgress color="primary" />
          <Typography variant="body1" className="loading-text">
            Loading unread messages...
          </Typography>
        </div>
      ) : error ? (
        <Alert severity="error" className="error-container">
          {error}
        </Alert>
      ) : unreadMessages.length === 0 ? (
        <Alert severity="info" className="empty-container">
          No unread messages found.
        </Alert>
      ) : (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Avatar</TableCell>
                <TableCell>Segment Name</TableCell>
                <TableCell>Poster</TableCell>
                <TableCell>Comments</TableCell>
                <TableCell>Timestamp</TableCell>
                <TableCell align="right">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {unreadMessages.map((message, index) => (
                <TableRow key={index} className="message-item">
                  <TableCell>
                    <Avatar>
                      <CommentIcon />
                    </Avatar>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h6" component="div">
                      {message.segment_name}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2" component="span">
                      {message.poster_name}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2" color="textSecondary">
                      {message.comment.split("\n")[0]}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="caption" className="timestamp">
                      {new Date(message.timestamp).toLocaleString()}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <IconButton
                      edge="end"
                      aria-label="go-to-discussion"
                      className="icon-button"
                      onClick={() => handleListItemClick(message.segment_id)}
                    >
                      <ArrowForwardIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Paper>
  );
};

export default JTInbox;
