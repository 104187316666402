import React, { useState, useEffect } from "react";
import { Container, Nav, Badge } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import {
  AiOutlineHome,
  AiOutlineTable,
  AiOutlineFileText,
  AiOutlineInbox,
} from "react-icons/ai";
import FilterModal from "./FilterModal";
import "./JTSide.css";
import api from "../services/api";

const JTSide = () => {
  const location = useLocation();
  const currentPath = location.pathname;
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [unreadCount, setUnreadCount] = useState(0);
  const [prevUnreadCount, setPrevUnreadCount] = useState(0);

  const userEmail = localStorage.getItem("username");
  const isRamUser = userEmail === "ram@4sight-global.com";

  // Function to request notification permission
  const requestNotificationPermission = async () => {
    if (Notification.permission === "default") {
      const permission = await Notification.requestPermission();
      console.log("Notification Permission:", permission);
    }
  };

  // Function to show a desktop notification
  const showNotification = (message) => {

    if (Notification.permission === "granted") {
    console.log("Notification permission granted");
      new Notification("Test Notification", {
        body: "You have a new message!",  
        icon: "notification_icon.png",
        requireInteraction: true, // Notification stays until dismissed
        vibrate: [200, 100, 200] // Vibrates (on supported devices)
    });
  
    } else {
      console.warn("Notifications are blocked or not granted.");
    }
  };

  useEffect(() => {
    let intervalId;

    // Request notification permission on component mount
    requestNotificationPermission();

    const fetchUnreadCount = async () => {
      try {
        const username = localStorage.getItem("username");
        if (!username) throw new Error("Username not found in local storage");

        const response = await api.get(
          `discussion/project_unread_count_total?username=${username}`
        );

        const newUnreadCount = response.data.total_unread_count || 0;

        // Only show notifications if permission is granted
        if (Notification.permission === "granted") {
          if (newUnreadCount > 0 && prevUnreadCount === 0) {
            showNotification("You have unread messages.");
          }

          if (newUnreadCount > prevUnreadCount) {
            showNotification("New message received!");
          }
        } else {
          console.warn("Notifications are not granted.");
        }

        setPrevUnreadCount(newUnreadCount);
        setUnreadCount(newUnreadCount);
      } catch (error) {
        console.error("Error fetching unread count:", error);
      }
    };

    fetchUnreadCount(); // Initial fetch

    // Update unread count every 3 seconds
    intervalId = setInterval(fetchUnreadCount, 1000);

    return () => clearInterval(intervalId);
  }, [prevUnreadCount]);

  return (
    <Container fluid className="jt-sidebar-container">
      <div className="sidebar">
        <Nav className="flex-column">
          <div className="nav-link-container">
            <Nav.Link
              href="/projects_list"
              className={`nav-link ${currentPath.startsWith("/projects_list") ? "active" : ""}`}
            >
              <AiOutlineHome className="icon" />
              <span>Home</span>
            </Nav.Link>
          </div>

          <div className="nav-link-container">
            <Nav.Link
              href="/project_inbox"
              className={`nav-link ${currentPath.startsWith("/project_inbox") ? "active" : ""}`}
            >
              <AiOutlineInbox className="icon" />
              <span>Inbox</span>
              {unreadCount > 0 && (
                <Badge pill bg="danger" className="ml-2">
                  {unreadCount}
                </Badge>
              )}
            </Nav.Link>
          </div>
          {isRamUser && (
          <div className="nav-link-container">
            <Nav.Link
              href="/assign-resource"
              className={`nav-link ${currentPath.startsWith("/assign-resource") ? "active" : ""}`}
            >
              <AiOutlineTable className="icon" />
              <span>Assign<br></br> Resource</span>
            </Nav.Link>
          </div>
          )}
            
          <div className="nav-link-container">
            <Nav.Link
              href="/create-new"
              className={`nav-link ${currentPath.startsWith("/create-new") ? "active" : ""}`}
            >
              <AiOutlineTable className="icon" />
              <span>Add New</span>
            </Nav.Link>
          </div>

          <div className="nav-link-container">
            <Nav.Link
              href="/project_summary"
              className={`nav-link ${currentPath.startsWith("/project_summary") ? "active" : ""}`}
            >
              <AiOutlineFileText className="icon" />
              <span>Reports</span>
            </Nav.Link>
          </div>

          {isRamUser && (
            <div className="nav-link-container">
              <Nav.Link
                href="/survey_insights"
                className={`nav-link ${currentPath.startsWith("/survey_insights") ? "active" : ""}`}
              >
                <AiOutlineFileText className="icon" />
                <span>Researchers Feedback</span>
              </Nav.Link>
            </div>
          )}
        </Nav>
      </div>
      <FilterModal showModal={showFilterModal} setShowModal={setShowFilterModal} />
    </Container>
  );
};

export default JTSide;
