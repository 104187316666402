import { useState, useEffect } from "react";
import axios from "axios";
import { ClipLoader } from "react-spinners"; // For loading spinner
import api from '../services/api';
export default function ImageResizer() {
    const [images, setImages] = useState([]);
    const [width, setWidth] = useState(500);
    const [height, setHeight] = useState(500);
    const [option, setOption] = useState(1);
    const [resizedImages, setResizedImages] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        api.get("img/images/", {
            headers: { Authorization: `Token ${localStorage.getItem("Token")}` }
        })
        .then(res => {
            setResizedImages(res.data);
            setIsLoading(false);
        })
        .catch(err => {
            console.error(err);
            setIsLoading(false);
        });
    }, []);

    const handleUpload = async (e) => {
        e.preventDefault();
        if (images.length === 0) return alert("Please select an image");

        const formData = new FormData();
        images.forEach(image => formData.append("image", image));
        formData.append("width", width);
        formData.append("height", height);
        formData.append("option", option);

        setIsLoading(true);
        try {
            const res = await api.post("img/resize/", formData, {
                headers: {
                    Authorization: `Token ${localStorage.getItem("Token")}`,
                    "Content-Type": "multipart/form-data",
                },
            });
            setResizedImages([...resizedImages, ...res.data]);
        } catch (err) {
            console.error("Upload Error:", err);
        } finally {
            setIsLoading(false);
        }
    };

    const handleDownloadAndDelete = (id, filename) => {
        api.get(`img/images/download/${id}/`, {
            headers: { Authorization: `Token ${localStorage.getItem("Token")}` },
            responseType: 'blob',
        })
        .then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const a = document.createElement('a');
            a.href = url;
            a.download = filename;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);

            return api.delete(`img/delete/${id}/`, {
                headers: { Authorization: `Token ${localStorage.getItem("Token")}` }
            });
        })
        .then(() => {
            setResizedImages(resizedImages.filter(img => img.id !== id));
        })
        .catch(error => console.error("Error:", error));
    };

    return (
        <div className="min-h-screen bg-gradient-to-r from-blue-50 to-purple-50 p-6">
            <div className="max-w-4xl mx-auto bg-white p-8 rounded-xl shadow-2xl">
                <h2 className="text-3xl font-bold text-center text-gray-800 mb-6">Image Resizer</h2>
                <form onSubmit={handleUpload} className="space-y-6">
                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-2">Select Images</label>
                        <input 
                            type="file" 
                            onChange={(e) => setImages([...e.target.files])} 
                            multiple
                            className="w-full border-2 border-dashed border-gray-300 rounded-lg p-4 hover:border-blue-500 transition duration-300 ease-in-out"
                        />
                    </div>
                    <div className="grid grid-cols-2 gap-6">
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-2">Width (px)</label>
                            <input 
                                type="number" 
                                value={width} 
                                onChange={(e) => setWidth(e.target.value)}
                                className="w-full border border-gray-300 rounded-lg p-3 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 transition duration-300 ease-in-out"
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-2">Height (px)</label>
                            <input 
                                type="number" 
                                value={height} 
                                onChange={(e) => setHeight(e.target.value)}
                                className="w-full border border-gray-300 rounded-lg p-3 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 transition duration-300 ease-in-out"
                            />
                        </div>
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-2">Resize Option</label>
                        <select 
                            value={option} 
                            onChange={(e) => setOption(e.target.value)}
                            className="w-full border border-gray-300 rounded-lg p-3 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 transition duration-300 ease-in-out"
                        >
                            <option value={1}>Add White Background</option>
                            <option value={2}>Stretch</option>
                            <option value={3}>Choose max dimension from input</option>
                        </select>
                    </div>
                    <button 
                        type="submit" 
                        disabled={isLoading}
                        className="w-full bg-blue-600 text-white p-3 rounded-lg hover:bg-blue-700 transition duration-300 ease-in-out flex justify-center items-center"
                    >
                        {isLoading ? <ClipLoader size={25} color={"#ffffff"} /> : "Resize Images"}
                    </button>
                </form>
            </div>

            <div className="max-w-4xl mx-auto mt-8">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                    {resizedImages.map(img => (
                        <div key={img.id} className="bg-white p-4 rounded-xl shadow-lg hover:shadow-xl transition duration-300 ease-in-out">
                            <img 
                                src={`https://4sightoperations.in/${img.resized_image}`} 
                                alt="Resized" 
                                className="w-full h-48 object-cover rounded-md"
                            />
                            <div className="mt-4 flex justify-center">
                                <button 
                                    onClick={() => handleDownloadAndDelete(img.id, img.resized_image)}
                                    className="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600 transition duration-300 ease-in-out"
                                >Download & Delete</button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}