import React, { useState, useEffect } from "react";
import api from "../services/api";
import { Container, Button, Form, Table, Alert, Card } from "react-bootstrap";
import { FaDownload, FaUpload, FaSyncAlt } from "react-icons/fa";
import 'bootstrap/dist/css/bootstrap.min.css';

const DocumentManager = () => {
    const [documents, setDocuments] = useState([]);
    const [file, setFile] = useState(null);
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");

    useEffect(() => {
        fetchDocuments();
    }, []);

    const fetchDocuments = async () => {
        try {
            const response = await api.get("/docs/documents/");
            setDocuments(response.data);
        } catch (err) {
            setError("Failed to fetch documents.");
        }
    };

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleUpload = async (e) => {
        e.preventDefault();
        if (!file) {
            setError("Please select a file.");
            return;
        }

        const formData = new FormData();
        formData.append("file", file);
        formData.append("title", file.name);

        try {
            await api.post("/docs/documents/", formData, {
                headers: { "Content-Type": "multipart/form-data" },
            });
            setSuccess("Document uploaded successfully.");
            setFile(null);
            fetchDocuments(); // Refresh document list
        } catch (err) {
            setError("Upload failed: " + err.message);
            console.error("Upload failed", err);
        }
    };

    const handleDownload = async (id, filename) => {
        try {
            const response = await api.get(`docs/documents/${id}/download/`, { responseType: "blob" });
    
            // Create a download link with the correct file type
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", filename);  // Use original filename
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (err) {
            setError("Download failed: " + err.message);
            console.error("Download failed", err);
        }
    };
    

    const handleReplace = async (id, newFile) => {
        if (!newFile) {
            setError("Please select a new file to replace.");
            return;
        }

        const formData = new FormData();
        formData.append("file", newFile);

        try {
            await api.post(`/docs/documents/${id}/replace/`, formData, {
                headers: { "Content-Type": "multipart/form-data" },
            });
            setSuccess("Document replaced successfully.");
            fetchDocuments(); // Refresh document list
        } catch (err) {
            setError("Replacement failed: " + err.message);
            console.error("Replacement failed", err);
        }
    };

    const handleReplaceFileChange = (id, e) => {
        if (!id) {
            setError("Invalid document ID.");
            return;
        }
    
        const newFile = e.target.files[0];
        if (newFile) {
            handleReplace(id, newFile);
        }
    };
    

    return (
        <Container className="mt-4">
            <h2 className="text-center mb-4">Document Manager</h2>

            {error && <Alert variant="danger">{error}</Alert>}
            {success && <Alert variant="success">{success}</Alert>}

            {/* Upload Form */}
            <Card className="mb-4">
                <Card.Header><FaUpload /> Upload New Checklist</Card.Header>
                <Card.Body>
                    <Form onSubmit={handleUpload}>
                        <Form.Group controlId="fileUpload">
                            <Form.Label>Select Document:</Form.Label>
                            <Form.Control type="file" onChange={handleFileChange} />
                        </Form.Group>
                        <Button type="submit" variant="primary" className="mt-2">Upload</Button>
                    </Form>
                </Card.Body>
            </Card>

            {/* Document List */}
            <Card>
            <Card.Header className="small"><FaSyncAlt /> Available Documents & Checklists</Card.Header>
<Card.Body className="small">
    <Table striped bordered hover responsive className="small">
        <thead>
            <tr>
                <th>Title</th>
                <th>Uploaded At</th>
                <th>Actions</th>
                <th>Replace with Latest File</th>
                <th>Uploaded By</th>
            </tr>
        </thead>
        <tbody>
            {documents.map((doc) => (
                <tr key={doc.id}>
                    <td>{doc.title}</td>
                    <td>{new Date(doc.uploaded_at).toLocaleString()}</td>
                    <td>
                        <Button variant="success" size="sm" onClick={() => handleDownload(doc.id, doc.title)}>
                            <FaDownload /> Download
                        </Button>
                    </td>
                    <td>
                        <Form.Group controlId={`replaceFile-${doc.id}`} className="m-0">
                            <Form.Control
                                type="file"
                                onChange={(e) => handleReplaceFileChange(doc.id, e)}
                                className="mt-2 file-input-small"
                            />
                        </Form.Group>
                    </td>
                    <td>{doc.uploader_name}</td>
                </tr>
            ))}
        </tbody>
    </Table>
</Card.Body>

            </Card>
        </Container>
    );
};

export default DocumentManager;
