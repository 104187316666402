import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Container, Row, Col, Button, Form, Alert } from 'react-bootstrap';
import { IoIosArrowForward } from 'react-icons/io';
import './Survey.css';

const SurveyDetails = () => {
  const location = useLocation();
  const staticData = location.state?.data || {}; // Accessing the staticData from location state

  const [currentStep, setCurrentStep] = useState(0);
  const [researcherInCharge, setResearcherInCharge] = useState(staticData.researcherInCharge || '');
  const [projectName, setProjectName] = useState(staticData.projectName || '');
  const [jobNumber, setJobNumber] = useState(staticData.jobNumber || '');
  const [year, setYear] = useState(staticData.year || '');
  const [month, setMonth] = useState(staticData.month || '');
  const [inCharge, setInCharge] = useState(staticData.scriptingInCharge || '');
  const [inCharge1, setInCharge1] = useState(staticData.dpInCharge || '');
  const [surveyId, setSurveyId] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [showAlert1, setShowAlert1] = useState(false);
  const [yearError, setYearError] = useState(''); // Error state for year validation

  const currentYear = new Date().getFullYear();

  useEffect(() => {
    if (staticData) {
      console.log('staticData:', staticData); // Debugging to ensure staticData is passed correctly
      setResearcherInCharge(staticData.researcherInCharge || '');
      setProjectName(staticData.projectName || '');
      setJobNumber(staticData.jobNumber || '');
      setYear(staticData.year || '');
      setMonth(staticData.month || '');
      setInCharge(staticData.scriptingInCharge || '');
      setInCharge1(staticData.dpInCharge || '');
    }
  }, [staticData]);

  const handleYearChange = (e) => {
    const inputYear = e.target.value;
    
    // Allow only numbers and limit input to 4 digits
    if (/^\d{0,4}$/.test(inputYear)) {
      setYear(inputYear);

      // Check if the entered year is greater than or equal to the current year
      if (inputYear && parseInt(inputYear, 10) < currentYear) {
        setYearError(`Year must be ${currentYear} or later.`);
      } else {
        setYearError('');
      }
    }
  };
  const handleFormSubmit = async () => {
    // Validate that all required fields are filled and there are no validation errors
    if (projectName && jobNumber && researcherInCharge && year && !yearError && month && inCharge && inCharge1) {
      setShowAlert(false); // Hide any existing error alerts
  
      // Prepare the form data to be sent to the backend
      const formData = {
        researcher_in_charge: researcherInCharge,
        project_name: projectName,
        job_number: jobNumber,
        year,
        month,
        dp_or_scripting: "Both", // Indicates the survey is for both DP and Scripting
        scripting_in_charge: inCharge,
        dp_in_charge: inCharge1,
        segment_id: staticData.segmentId || null, // Include segment_id if available
      };
  
      try {
        // Make the API call to the backend
        const response = await fetch('http://localhost:8000/survey/create-survey/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${localStorage.getItem('Token')}`, // Include user authentication token
          },
          body: JSON.stringify(formData), // Send the form data as a JSON payload
        });
  
        if (response.ok) {
          // Parse the response JSON
          const data = await response.json();
  
          // Extract and save the generated survey ID from the response
          setSurveyId(data.survey_id);
  
          // Move to the next step in the UI
          setCurrentStep(1);
        } else {
          // Handle non-successful responses
          const errorData = await response.json();
          console.error('Failed to create survey:', errorData);
          // Optionally, show an error message to the user
          setShowAlert(true);
        }
      } catch (error) {
        // Handle any network or unexpected errors
        console.error('Error while creating survey:', error);
        // Optionally, show an error message to the user
        setShowAlert(true);
      }
    } else {
      // Show an error alert if required fields are missing or invalid
      setShowAlert(true);
    }
  };
    
  
  const handleCopyLink = () => {
    const link = `${window.location.origin}/satisfactionsurvey/${surveyId}`;
    navigator.clipboard.writeText(link);
    setShowAlert1(true);
    setTimeout(() => setShowAlert1(false), 2000);
  };

  return (
    <Container className="my-4">
      {currentStep === 0 && (
        <>
          {showAlert && (
            <Alert variant="danger" onClose={() => setShowAlert(false)} dismissible>
              Please fill in all required fields!
            </Alert>
          )}
          <div className="form-container p-4 rounded shadow-sm" style={{ backgroundColor: 'rgba(255, 255, 255, 0.9)' }}>
            <h5 className="mb-4">Survey Details</h5>
            <Form>
              {/* Researcher In Charge */}
              <Form.Group as={Row} controlId="formResearcher">
                <Form.Label column sm="4">Researcher In Charge:</Form.Label>
                <Col sm="8">
                  <Form.Control type="text" value={staticData.researcherInCharge} readOnly />
                </Col>
              </Form.Group>

              {/* Project Name */}
              <Form.Group as={Row} controlId="formProjectName" className="mt-3">
                <Form.Label column sm="4">Project Name:</Form.Label>
                <Col sm="8">
                  <Form.Control type="text" value={staticData.projectName} readOnly />
                </Col>
              </Form.Group>

              {/* Job Number */}
              <Form.Group as={Row} controlId="formJobNumber" className="mt-3">
                <Form.Label column sm="4">Job Number:</Form.Label>
                <Col sm="8">
                  <Form.Control type="text" value={staticData.jobNumber} readOnly />
                </Col>
              </Form.Group>

              {/* Year with Validation */}
              <Form.Group as={Row} controlId="formYear" className="mt-3">
                <Form.Label column sm="4">Year:</Form.Label>
                <Col sm="8">
                  <Form.Control
                    type="text"
                    value={staticData.year}
                    onChange={handleYearChange}
                    isInvalid={!!yearError}
                    readOnly
                  />
                  <Form.Control.Feedback type="invalid">
                    {yearError}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>

              {/* Month */}
              <Form.Group as={Row} controlId="formMonth" className="mt-3">
                <Form.Label column sm="4">Month:</Form.Label>
                <Col sm="8">
                  <Form.Control type="text" value= {staticData.month} readOnly />
                </Col>
              </Form.Group>

              {/* Scripting In-Charge */}
              <Form.Group as={Row} controlId="formScriptingInCharge" className="mt-3">
                <Form.Label column sm="4">Scripting In-Charge:</Form.Label>
                <Col sm="8">
                  <Form.Control type="text" value={staticData.scriptingInCharge} readOnly />
                </Col>
              </Form.Group>

              {/* DP In-Charge */}
              <Form.Group as={Row} controlId="formDPInCharge" className="mt-3">
                <Form.Label column sm="4">DP In-Charge:</Form.Label>
                <Col sm="8">
                  <Form.Control type="text" value={staticData.dpInCharge} readOnly />
                </Col>
              </Form.Group>

              {/* Submit Button */}
              <div className="d-flex justify-content-end mt-3">
                <Button variant="primary" onClick={handleFormSubmit}>
                  Proceed <IoIosArrowForward />
                </Button>
              </div>
            </Form>
          </div>
        </>
      )}

      {currentStep === 1 && surveyId && (
        <div className="text-center mt-5">
          <div className="p-3 rounded shadow-sm" style={{ backgroundColor: 'rgba(255, 255, 255, 0.9)', display: 'inline-block' }}>
            <h5>Survey Link</h5>
            <div className="d-flex align-items-center justify-content-center mt-2">
              <Form.Control
                type="text"
                value={`${window.location.origin}/satisfactionsurvey/${surveyId}`}
                readOnly
                style={{ width: '300px', marginRight: '10px' }}
              />
              <Button variant="outline-secondary" onClick={handleCopyLink}>
                Copy Link
              </Button>
            </div>
            {showAlert1 && <Alert variant="success" className="mt-2">Link Copied!</Alert>}
          </div>
        </div>
      )}
    </Container>
  );
};

export default SurveyDetails;
