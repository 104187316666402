import React, { useState } from "react";
import { Container, Nav, Navbar, Offcanvas } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { AiOutlineHome, AiOutlineFunnelPlot, AiOutlineMessage, AiOutlineBook, AiOutlineTable, AiOutlineMenu } from "react-icons/ai";
import FilterModal from "./FilterModal";
import "./SideNavbar.css";

const SideNavbar = () => {
  const location = useLocation();
  const currentPath = location.pathname;
  const [showSidebar, setShowSidebar] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);

  return (
    <>
      {/* Toggle Button for Mobile */}
      <Navbar className="d-lg-none bg-dark text-white p-2">
        <AiOutlineMenu size={30} onClick={() => setShowSidebar(true)} className="ms-3" />
        <h5 className="mx-auto my-0">4Sight Research</h5>
      </Navbar>

      {/* Sidebar for Larger Screens */}
      <div className="sidebar d-none d-lg-block">
        <Nav className="flex-column">
          <div className="nav-link-container">
            <Nav.Link href="/home" className={`nav-link ${currentPath === "/home" ? "active" : ""}`}>
              <AiOutlineHome /> <span>Home</span>
            </Nav.Link>
          </div>

          <div className="nav-link-container">
            <Nav.Link href="/cross-tab" className={`nav-link ${currentPath === "/cross-tab" ? "active" : ""}`}>
              <AiOutlineTable /> <span>CrossTab</span>
            </Nav.Link>
          </div>

          <div className="nav-link-container">
            <Nav.Link href="/chatbot" className={`nav-link ${currentPath === "/chatbot" ? "active" : ""}`}>
              <AiOutlineMessage /> <span>ChatBot</span>
            </Nav.Link>
          </div>

          <div className="nav-link-container">
            <Nav.Link href="/norms" className={`nav-link ${currentPath === "/norms" ? "active" : ""}`}>
              <AiOutlineBook /> <span>Norms</span>
            </Nav.Link>
          </div>

          <div className="nav-link-container">
            <Nav.Link href="/filters" className={`nav-link ${currentPath === "/filters" ? "active" : ""}`}>
              <AiOutlineFunnelPlot /> <span>Filters</span>
            </Nav.Link>
          </div>
        </Nav>
      </div>

      {/* Mobile Offcanvas Sidebar */}
      <Offcanvas show={showSidebar} onHide={() => setShowSidebar(false)} responsive="lg">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Navigation</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Nav className="flex-column">
            <div className="nav-link-container">
              <Nav.Link href="/home" className={`nav-link ${currentPath === "/home" ? "active" : ""}`} onClick={() => setShowSidebar(false)}>
                <AiOutlineHome /> <span>Home</span>
              </Nav.Link>
            </div>

            <div className="nav-link-container">
              <Nav.Link href="/cross-tab" className={`nav-link ${currentPath === "/cross-tab" ? "active" : ""}`} onClick={() => setShowSidebar(false)}>
                <AiOutlineTable /> <span>CrossTab</span>
              </Nav.Link>
            </div>

            <div className="nav-link-container">
              <Nav.Link href="/chatbot" className={`nav-link ${currentPath === "/chatbot" ? "active" : ""}`} onClick={() => setShowSidebar(false)}>
                <AiOutlineMessage /> <span>ChatBot</span>
              </Nav.Link>
            </div>

            <div className="nav-link-container">
              <Nav.Link href="/norms" className={`nav-link ${currentPath === "/norms" ? "active" : ""}`} onClick={() => setShowSidebar(false)}>
                <AiOutlineBook /> <span>Norms</span>
              </Nav.Link>
            </div>

            <div className="nav-link-container">
              <Nav.Link href="/filters" className={`nav-link ${currentPath === "/filters" ? "active" : ""}`} onClick={() => setShowSidebar(false)}>
                <AiOutlineFunnelPlot /> <span>Filters</span>
              </Nav.Link>
            </div>
          </Nav>
        </Offcanvas.Body>
      </Offcanvas>

      <FilterModal showModal={showFilterModal} setShowModal={setShowFilterModal} />
    </>
  );
};

export default SideNavbar;
