import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";

import {
  Container,
  Typography,
  TextField,
  Button,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  Alert,
  Paper,
  Badge,
  Box,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { Send as SendIcon } from "@mui/icons-material";
import api from "../services/api";
export const JTDiscussionPage = () => {
  const location = useLocation();
  const { segmentId } = location.state || {}; // Extract segmentId from URL params
  const email = localStorage.getItem("username"); // Get the email stored in localStorage

  const [comment, setComment] = useState("");
  const [comments, setComments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentUserName, setCurrentUserName] = useState(null); // To store the current user's actual username
  const [unreadCount, setUnreadCount] = useState(0); // To track unread count

  // Fetch the current user's actual username from the backend using the email
  useEffect(() => {
    if (!email) {
      setError("Email ID is missing in localStorage.");
      setLoading(false);
      return;
    }

    // Fetch user info from backend (assuming the backend has an endpoint for user profile)
    const fetchUserProfile = async () => {
      try {
        const response = await api.get(`users/profile/`, {
          headers: {
            Authorization: `Token ${localStorage.getItem("Token")}`,
          },
        });
        const { username } = response.data; // Assuming the response contains a `username` field
        setCurrentUserName(username); // Store the actual username
      } catch (err) {
        setError("Failed to fetch user information.");
      } finally {
        setLoading(false);
      }
    };

    fetchUserProfile();
  }, [email]);

  // Fetch comments when segmentId changes
  useEffect(() => {
    if (!segmentId) {
      setError("Segment ID is missing.");
      setLoading(false);
      return;
    }
    fetchComments(); // Fetch comments if segmentId is available
    fetchUnreadCount(segmentId); // Fetch unread count when segmentId is available
  }, [segmentId]);

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  const handleCommentSubmit = async (e) => {
    e.preventDefault();
    if (comment.trim() !== "") {
      try {
        setLoading(true);
        await api.post(
          `discussion/create/`,
          {
            segment: segmentId,
            userId: email, // Send email as user ID
            comment: comment,
          },
          {
            headers: {
              Authorization: `Token ${localStorage.getItem("Token")}`,
            },
          }
        );
        setComment(""); // Reset comment input
        fetchComments(); // Fetch the updated comments
        fetchUnreadCount(segmentId); // Update unread count after comment is posted
      } catch (err) {
        setError("Failed to post comment. Please try again.");
      } finally {
        setLoading(false);
      }
    }
  };

  // Fetch comments for the specific segment
  const fetchComments = async () => {
    if (!segmentId) {
      setError("Segment ID is missing.");
      setLoading(false);
      return;
    }
    setLoading(true);
    setError(null);

    try {
      const response = await api.get(
        `discussion/list/?segment=${segmentId}`,
        {
          headers: {
            Authorization: `Token ${localStorage.getItem("Token")}`,
          },
        }
      );

      // Replace userId with the corresponding username for each comment
      const commentsWithUsernames = await Promise.all(
        response.data.map(async (commentData) => {
          const usernameResponse = await api.get(
            `users/get_username_by_id/`,
            {
              params: { user_id: commentData.user }, // Fetch username by user ID
              headers: {
                Authorization: `Token ${localStorage.getItem("Token")}`,
              },
            }
          );

          // If the username fetch is successful, update the comment data
          if (usernameResponse.data.username) {
            commentData.user = usernameResponse.data.username; // Replace user ID with username
          }
          return commentData;
        })
      );

      setComments(commentsWithUsernames); // Update comments state with usernames
    } catch (err) {
      setError("Failed to fetch comments. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  // Fetch unread count for the current user
  const fetchUnreadCount = async (segmentId) => {
    try {
      const response = await api.get(
        `discussion/unread_count/?segment_id=${segmentId}`,
        {
          method: "GET",
          headers: {
            Authorization: `Token ${localStorage.getItem("Token")}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (!response.ok) {
        throw new Error("Failed to fetch unread count");
      }
      const data = await response.json();
      setUnreadCount(data.unreadCount || 0); // Set the unread count in state
    } catch (error) {
      console.error("Error fetching unread count:", error);
      setUnreadCount(0); // Default to 0 if an error occurs
    }
  };

  // Mark a comment as read
  const markCommentAsRead = async (commentId) => {
    try {
      // Fetch the segmentId and discussionId (commentId in this case) from the commentData
      const discussionId = commentId; // Assuming commentId corresponds to the discussion ID in your database

      await api.post(
        `discussion/mark_as_read/`,
        { segment_id: segmentId, discussion_id: discussionId }, // Pass segment_id and discussion_id
        {
          headers: {
            Authorization: `Token ${localStorage.getItem("Token")}`,
          },
        }
      );
      fetchUnreadCount(segmentId); // Re-fetch unread count after marking as read
      fetchComments(); // Re-fetch comments to update their read status
    } catch (err) {
      setError("Failed to mark comment as read.");
    }
  };

  return (
    <Container maxWidth="md" sx={{ padding: "30px", height: "100vh", display: "flex", flexDirection: "column" }}>
      <Typography variant="h4" gutterBottom>
        Project Discussion
      </Typography>

      {/* Show loading spinner while fetching comments */}
      {loading && <CircularProgress sx={{ alignSelf: "center" }} />}

      {/* Show error message if there was an issue */}
      {error && (
        <Alert severity="error" sx={{ marginBottom: "20px" }}>
          {error}
        </Alert>
      )}

      {/* Unread count */}
      <Typography variant="h6" gutterBottom>
        Unread Comments: {unreadCount}
      </Typography>

      {/* Discussion list */}
      <Paper elevation={3} sx={{ flex: 1, overflowY: "auto", padding: "16px", marginBottom: "20px" }}>
        <Typography variant="h6" gutterBottom>
          Discussion:
        </Typography>
        <List>
          {comments.length > 0 ? (
            comments.map((commentData, index) => (
              <ListItem
                key={index}
                sx={{
                  display: "flex",
                  justifyContent: commentData.user === currentUserName ? "flex-end" : "flex-start",
                  marginBottom: "8px",
                }}
                onClick={() => markCommentAsRead(commentData.id)} // Mark as read on click
              >
                <Paper
                  elevation={2}
                  sx={{
                    padding: "8px 16px",
                    backgroundColor: commentData.user === currentUserName ? "#e3f2fd" : commentData.is_read ? "#f5f5f5" : "#ffebee", // Change background for unread messages
                    borderRadius: "12px",
                    maxWidth: "70%",
                  }}
                >
                  <ListItemText
                    primary={
                      <Typography variant="body1" fontWeight="bold">
                        {commentData.user}
                        {!commentData.is_read && (
                          <Badge color="error" badgeContent="UR" sx={{ marginLeft: "8px" }} />
                        )}
                      </Typography>
                    }
                    secondary={
                      <Typography variant="body2" color="textSecondary">
                        <span dangerouslySetInnerHTML={{ __html: commentData.comment }} /> {/* Render HTML comment */}
                      </Typography>
                    }
                  />
                </Paper>
              </ListItem>
            ))
          ) : (
            <Typography variant="body1" color="textSecondary">
              No comments yet. Be the first to comment!
            </Typography>
          )}
        </List>
      </Paper>

      {/* Textbox for adding comments */}
      <Box
        component="form"
        onSubmit={handleCommentSubmit}
        sx={{
          display: "flex",
          alignItems: "center",
          backgroundColor: "white",
          borderRadius: "8px",
          padding: "8px",
          boxShadow: 2,
          marginTop: "auto",
        }}
      >
        <TextField
          fullWidth
          multiline
          rows={2}
          placeholder="Write your comment here..."
          value={comment}
          onChange={handleCommentChange}
          variant="outlined"
          sx={{
            flexGrow: 1,
            marginRight: "8px",
            "& .MuiOutlinedInput-root": {
              borderRadius: "8px",
              "& fieldset": {
                borderColor: "transparent",
              },
              "&:hover fieldset": {
                borderColor: "transparent",
              },
              "&.Mui-focused fieldset": {
                borderColor: "transparent",
              },
            },
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  type="submit"
                  color="primary"
                  disabled={loading}
                  sx={{
                    backgroundColor: "primary.main",
                    color: "white",
                    borderRadius: "50%",
                    padding: "10px",
                    "&:hover": {
                      backgroundColor: "primary.dark",
                    },
                  }}
                >
                  <SendIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>
    </Container>
  );
};

export default JTDiscussionPage;
