import React, { useState } from "react";
import { Form, Button, Toast, ToastContainer } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import api from "../services/api"; // <-- Import your custom Axios instance

const EditResource = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { segmentId, jobNumber, editMode } = location.state;
  //alert(segmentId);

  const resources = ['Anuradha', 'Nagajyothi', 'Raguram', 'Saravanakumar', 'Srivendhan', 'Varun', 'Not Applicable'];
  const contactPersonsDP = ['Anuradha', 'Nagajyothi', 'Raguram', 'Saravanakumar', 'Srivendhan', 'Varun', 'Not Applicable'];

  const [selectedResource, setSelectedResource] = useState("");
  const [selectedContactPersonDP, setSelectedContactPersonDP] = useState("");
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const handleAssign = async () => {
    try {
      // Retrieve the stored token (assuming it's stored in localStorage)
      const token = localStorage.getItem("Token");
  
      if (!token) {
        setSuccessMessage("Authorization token is missing. Please log in.");
        setShowSuccessToast(true);
        return;
      }
  
      const response = await api.post(
        `api/segments/update-contact-person/`,
        {
          segmentId,
          contactPersonForScripting: selectedResource,
          contactPersonForDP: selectedContactPersonDP,
        },
        {
          headers: {
            Authorization: `Token ${token}`, // Attach token to request
          },
        }
      );
  
      setSuccessMessage("Resources assigned successfully!");
      setShowSuccessToast(true);
      navigate("/resource-unassigned");
    } catch (error) {
      console.error("Error assigning resources:", error);
      setSuccessMessage("Error assigning resources.");
      setShowSuccessToast(true);
    }
  };
  

  return (
    <div className="container mt-4" style={{ width: "50%" }}>
      <h3>Edit Resource</h3>
      <Form>
        <Form.Group controlId="formContactPersonForScripting">
          <Form.Label>Contact Person for Scripting</Form.Label>
          <Form.Control
            as="select"
            value={selectedResource}
            onChange={(e) => setSelectedResource(e.target.value)}
          >
            <option value="">Select a resource</option>
            {resources.map((resource) => (
              <option key={resource} value={resource}>
                {resource}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
        <Form.Group controlId="formContactPersonForDP" className="mt-3">
          <Form.Label>Contact Person for DP</Form.Label>
          <Form.Control
            as="select"
            value={selectedContactPersonDP}
            onChange={(e) => setSelectedContactPersonDP(e.target.value)}
          >
            <option value="">Select a contact person</option>
            {contactPersonsDP.map((contactPerson) => (
              <option key={contactPerson} value={contactPerson}>
                {contactPerson}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
        <Button className="mt-3" variant="primary" onClick={handleAssign}>
          Assign
        </Button>
      </Form>

      <ToastContainer position="bottom-end" className="p-3">
        <Toast
          onClose={() => setShowSuccessToast(false)}
          show={showSuccessToast}
          delay={3000}
          autohide
          bg="success"
        >
          <Toast.Header>
            <strong className="me-auto">Success</strong>
          </Toast.Header>
          <Toast.Body>{successMessage}</Toast.Body>
        </Toast>
      </ToastContainer>
    </div>
  );
};

export default EditResource;